import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  IconPlusCircle,
  IconSettings,
  IconSliderLeft,
  IconSliderRight,
  IconUpload
} from '../Icons';
import { TargetButtonManager } from '../TargetButton';
import { Background } from '../Palette/Background';
import { Buttons } from '../Palette/Buttons';
import { TargetForm } from '../TargetForm';
import { Modal } from '../Modal';
import { changeStyles } from '../../api/api';
import { GRADIENTS_PRESET } from '../../utils';
import styles from './Personal.module.scss';

export const Personal = ({
  videoDetails,
  setVideoDetails,
  targetButtons,
  setTargetButtons,
  setLogoFile,
  setPosterFile,
  handleTargetButtonChange,
  handleDeleteTargetButton
}) => {
  const location = useLocation();
  const { encryptedProjectId } = location.state || {};

  const fileLogoRef = useRef(null);
  const filePosterRef = useRef(null);

  const [modalState, setModalState] = useState({
    targetButtons: false,
    backgroundStyle: false,
    buttonSettings: false
  });

  const [logoPreview, setLogoPreview] = useState(null);
  const [posterPreview, setPosterPreview] = useState(null);
  const [isWatermark, setIsWatermark] = useState(videoDetails?.watermark || false);

  const [selectedColorType, setSelectedColorType] = useState('color');
  const [colorPickerValue, setColorPickerValue] = useState('#82919e');

  const [, setCurrentGradientIndex] = useState(0);
  const [btnColor, setBtnColor] = useState({
    color: '',
    backgroundColor: '',
    borderColor: '',
    fontWeight: null,
    hover: {
      color: '',
      backgroundColor: '',
      borderColor: ''
    },
    active: {
      color: '',
      backgroundColor: '',
      borderColor: ''
    }
  });
  const [bgColor, setBgColor] = useState({
    backgroundColor: '',
    backgroundImage: ''
  });

  useEffect(() => {
    if (videoDetails.logo && videoDetails.poster) {
      setLogoPreview(videoDetails.logo);
      setPosterPreview(videoDetails.poster);
    }
  }, [videoDetails.logo, videoDetails.poster]);

  useEffect(() => {
    if (videoDetails.styles) {
      // Обновляем состояние для стилей кнопки
      setBtnColor(prevBtnColor => ({
        ...prevBtnColor,
        color: videoDetails.styles.button.color || prevBtnColor.color,
        backgroundColor: videoDetails.styles.button.backgroundColor || prevBtnColor.backgroundColor,
        borderColor: videoDetails.styles.button.borderColor || prevBtnColor.borderColor,
        fontWeight: videoDetails.styles.button.fontWeight || prevBtnColor.fontWeight,
        hover: {
          color: videoDetails.styles.button.hover?.color || prevBtnColor.hover.color,
          backgroundColor:
            videoDetails.styles.button.hover?.backgroundColor || prevBtnColor.hover.backgroundColor,
          borderColor:
            videoDetails.styles.button.hover?.borderColor || prevBtnColor.hover.borderColor
        },
        active: {
          color: videoDetails.styles.button.active?.color || prevBtnColor.active.color,
          backgroundColor:
            videoDetails.styles.button.active?.backgroundColor ||
            prevBtnColor.active.backgroundColor,
          borderColor:
            videoDetails.styles.button.active?.borderColor || prevBtnColor.active.borderColor
        }
      }));

      // Обновляем состояние для стилей фона
      setBgColor(prevBgColor => ({
        ...prevBgColor,
        backgroundColor:
          videoDetails.styles.background.backgroundColor || prevBgColor.backgroundColor,
        backgroundImage:
          videoDetails.styles.background.backgroundImage || prevBgColor.backgroundImage
      }));
    }
  }, [videoDetails]);

  const openModal = modalName => {
    setModalState(prevState => ({
      ...prevState,
      [modalName]: true
    }));
  };

  const closeModal = modalName => {
    setModalState(prevState => ({
      ...prevState,
      [modalName]: false
    }));
  };

  const updateBgColor = (color = '', gradientIndex = 0) => {
    if (color) {
      setBgColor({
        backgroundColor: color,
        backgroundImage: ''
      });
    } else if (gradientIndex !== null) {
      setBgColor({
        backgroundColor: '',
        backgroundImage: GRADIENTS_PRESET[gradientIndex]
      });
    }
  };

  const handleNext = () => {
    setCurrentGradientIndex(prevIndex => {
      const newIndex = prevIndex === GRADIENTS_PRESET.length - 1 ? 0 : prevIndex + 1;
      updateBgColor('', newIndex);
      return newIndex;
    });
  };

  const handlePrevious = () => {
    setCurrentGradientIndex(prevIndex => {
      const newIndex = prevIndex === 0 ? GRADIENTS_PRESET.length - 1 : prevIndex - 1;
      updateBgColor('', newIndex);
      return newIndex;
    });
  };

  const handleButtonLogoClick = () => {
    if (fileLogoRef.current) {
      fileLogoRef.current.click();
    }
  };

  const handleButtonPosterClick = () => {
    if (filePosterRef.current) {
      filePosterRef.current.click();
    }
  };

  const handleLogoUpload = event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setLogoFile(file);
    }
  };

  const handlePosterUpload = event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPosterPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setPosterFile(file);
    }
  };

  const handleChangeWatermark = async e => {
    const status = e.target.checked;
    setIsWatermark(status);
    setVideoDetails({ ...videoDetails, watermark: status });
  };

  const updateBackground = newColor => {
    if (newColor.includes('gradient')) {
      setBgColor({
        backgroundColor: '',
        backgroundImage: newColor
      });
    } else {
      // handleColorChange(newColor);
      setBgColor({
        backgroundColor: newColor,
        backgroundImage: ''
      });
    }
  };

  const handleSaveColors = async () => {
    const stylesToSave = {
      ...(Object.keys(btnColor).length > 0 && { button: btnColor }),
      ...(Object.keys(bgColor).length > 0 && { background: bgColor })
    };

    if (encryptedProjectId) {
      await changeStyles(encryptedProjectId, stylesToSave);
    }
  };

  return (
    <>
      <h2 className={styles.title}>Настройка персонализации страницы</h2>
      <div className={styles.personal}>
        <section>
          <div className={styles.personalLogoContainer}>
            <h2 className={styles.personalTitle}>Лого</h2>
            <input
              type="file"
              name="logo"
              ref={fileLogoRef}
              style={{ display: 'none' }}
              onChange={handleLogoUpload}
              accept=".jpg,.jpeg,.png,.svg"
            />
            <button onClick={handleButtonLogoClick} className={styles.personalLogo}>
              {logoPreview ? (
                <div className={styles.logoWrapper}>
                  <img
                    src={logoPreview}
                    alt="Предпросмотр логотипа"
                    className={styles.personalLogoImg}
                  />
                  <div className={styles.iconOverlay}>
                    <IconUpload />
                  </div>
                </div>
              ) : (
                <IconUpload />
              )}
            </button>
          </div>

          <div className={styles.personalLogoContainer}>
            <h2 className={styles.personalTitle}>Постер</h2>
            <input
              type="file"
              name="poster"
              ref={filePosterRef}
              style={{ display: 'none' }}
              onChange={handlePosterUpload}
              accept=".jpg,.jpeg,.png,.svg"
            />
            <button onClick={handleButtonPosterClick} className={styles.personalPoster}>
              {posterPreview ? (
                <div className={styles.logoWrapper}>
                  <img
                    src={posterPreview}
                    alt="Предпросмотр постера"
                    className={styles.personalLogoImg}
                  />
                  <div className={styles.iconOverlay}>
                    <IconUpload />
                  </div>
                </div>
              ) : (
                <IconUpload />
              )}
            </button>
          </div>
        </section>
        <section style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={styles.watermark}>
            <label className={styles.watermarkLabel}>
              <input
                type="checkbox"
                name="watermark"
                value={isWatermark}
                onChange={handleChangeWatermark}
                checked={!!videoDetails.watermark}
              />
              Добавить логотип на видео
            </label>
          </div>
          <label className={styles.personalTitle}>Заголовок</label>
          <input
            type="text"
            value={videoDetails.title || ''}
            onChange={e => setVideoDetails({ ...videoDetails, title: e.target.value })}
            placeholder="Введите заголовок страницы"
            className={styles.personalInput}
            style={{ marginBottom: 16 }}
          />
          <label className={styles.personalTitle}>Описание</label>
          <textarea
            value={videoDetails.description || ''}
            onChange={e => setVideoDetails({ ...videoDetails, description: e.target.value })}
            placeholder="Введите описание страницы"
          />
        </section>
        <section>
          <h2 className={styles.personalTitle}>Цвет фона страницы</h2>
          <div className={styles.personalBg}>
            <div className={styles.personalBgSlider}>
              <button onClick={handlePrevious} className={styles.personalBgSliderWrapperButton}>
                <IconSliderLeft />
              </button>
              <button
                onClick={() => {
                  updateBackground(
                    bgColor.backgroundImage ? bgColor.backgroundImage : bgColor.backgroundColor
                  );
                  handleSaveColors();
                  openModal('backgroundStyle');
                }}
                className={styles.personalBgSliderSettings}
                style={{
                  backgroundImage: bgColor.backgroundImage ? bgColor.backgroundImage : 'none',
                  backgroundColor: bgColor.backgroundImage ? 'none' : bgColor.backgroundColor
                }}
              >
                <IconSettings />
              </button>
              <button onClick={handleNext} className={styles.personalBgSliderWrapperButton}>
                <IconSliderRight />
              </button>
            </div>
          </div>
        </section>
      </div>
      <div>
        <div className={styles.personalColors}>
          <button
            onClick={() => openModal('targetButtons')}
            className={styles.personalColorsButton}
          >
            <IconPlusCircle />
            Добавить кнопку целевого действия
          </button>
          <button
            onClick={() => openModal('buttonSettings')}
            className={styles.personalColorsButton}
          >
            Настроить внешний вид кнопки <IconSettings />
          </button>
        </div>
        <div className={styles.personalTargets}>
          <h2 className={styles.title}>Кнопки</h2>
          <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap' }}>
            <TargetButtonManager
              targetButtons={targetButtons}
              setTargetButtons={setTargetButtons}
              handleTargetButtonChange={handleTargetButtonChange}
              handleDeleteTargetButton={handleDeleteTargetButton}
            />
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalState.targetButtons}
        hideCross={true}
        onClose={() => closeModal('targetButtons')}
      >
        <TargetForm
          onClose={() => closeModal('targetButtons')}
          targetButtons={targetButtons}
          setTargetButtons={setTargetButtons}
        />
      </Modal>

      <Modal
        isOpen={modalState.backgroundStyle}
        onClose={() => closeModal('backgroundStyle')}
        hideCross={true}
      >
        <Background
          onClose={() => closeModal('backgroundStyle')}
          updateBackground={updateBackground}
          handleSaveColors={handleSaveColors}
        />
      </Modal>

      <Modal
        isOpen={modalState.buttonSettings}
        onClose={() => closeModal('buttonSettings')}
        hideCross={true}
      >
        <Buttons
          onClose={() => closeModal('buttonSettings')}
          btnColor={btnColor}
          setBtnColor={setBtnColor}
          selectedColorType={selectedColorType}
          setSelectedColorType={setSelectedColorType}
          colorPickerValue={colorPickerValue}
          setColorPickerValue={setColorPickerValue}
          handleSaveColors={handleSaveColors}
        />
      </Modal>
    </>
  );
};
