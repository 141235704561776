import React from 'react';
import styles from './VideoPlayer.module.scss';

export const VideoPlayer = ({ showPlayer, setShowPlayer, selectedVideo }) =>
  showPlayer && (
    <div onClick={() => setShowPlayer(false)} className={styles.player}>
      <div className={styles.playerContent}>
        <video width="600" controls autoPlay>
          <source src={selectedVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
