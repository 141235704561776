import React from 'react';
import logo_new from '../assets/images/logo_new.png';
import styles from './NotFound.module.scss';

const NotFound = () => {
  return (
    <section>
      <a href="/projects">
        <img src={logo_new} alt="Логотип ПЕРСИКОММ" className={styles.logo} />
      </a>

      <div className={styles.page}>
        <h1 className={styles.pageTitle}>Страница не найдена!</h1>

        <a href="/projects" className={styles.pageLink}>
          Вернуться на Главную страницу
        </a>
      </div>
    </section>
  );
};

export default NotFound;
