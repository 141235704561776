import React, { useState } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { IconRotate, IconSwap } from '../../../Icons';
import styles from './Gradient.module.scss';
import './gradientpicker.css';

export const Gradient = ({ updateBackground }) => {
  const [gradientDeg, setGradientDeg] = useState(90);
  const [gradientColors, setGradientColors] = useState([
    { color: '#ff0000', position: 0 },
    { color: '#0000ff', position: 100 }
  ]);
  const [activeColorIndex, setActiveColorIndex] = useState(0);

  const toggleGradientDeg = () => {
    const newDeg = gradientDeg === 90 ? 180 : 90;
    setGradientDeg(newDeg);
    updateBackground(generateGradient(gradientColors, newDeg));
  };

  const toggleColorsOrder = () => {
    const reversedColors = gradientColors.map((_, index, arr) => ({
      color: arr[arr.length - 1 - index].color,
      position: gradientColors[index].position
    }));

    setGradientColors(reversedColors);
    updateBackground(generateGradient(reversedColors, gradientDeg));
  };

  const updateGradientColor = (index, newColor) => {
    const newGradientColors = gradientColors.map((color, i) =>
      i === index ? { ...color, color: newColor } : color
    );
    setGradientColors(newGradientColors);
    updateBackground(generateGradient(newGradientColors));
  };

  const generateGradient = (colors, deg = gradientDeg) => {
    return `linear-gradient(${deg}deg, ${colors
      .map(({ color, position }) => `${color} ${position}%`)
      .join(', ')})`;
  };

  return (
    <section className={styles.gradient}>
      <section className="gradient">
        <div className={styles.gradientPreview}>
          <div
            className={styles.gradientPreviewDiv}
            style={{ backgroundImage: generateGradient(gradientColors) }}
          ></div>
          <div className={styles.gradientPreviewButtons}>
            <button
              onClick={toggleGradientDeg}
              className={styles.gradientPreviewButtonsIcon}
              style={{ rotate: '45deg' }}
            >
              <IconRotate />
            </button>
            <button onClick={toggleColorsOrder} className={styles.gradientPreviewButtonsIcon}>
              <IconSwap />
            </button>
          </div>
        </div>
        <div className={styles.controls}>
          <HexColorPicker
            color={gradientColors[activeColorIndex].color}
            onChange={newColor => updateGradientColor(activeColorIndex, newColor)}
          />
          <div className={styles.controlsHue}>
            {gradientColors.map((gradientColor, index) => (
              <div
                key={index}
                className={`${styles.gradientColorItem} ${
                  activeColorIndex === index ? styles.active : ''
                }`}
              >
                <div
                  className={styles.controlsHueWrapper}
                  onClick={() => setActiveColorIndex(index)}
                >
                  <div className={styles.controlsHuePreview}>
                    <div
                      className={styles.controlsHuePreviewView}
                      style={{ background: gradientColor.color }}
                    ></div>
                    <div className={styles.controlsHuePreviewInput}>
                      #
                      <HexColorInput
                        color={gradientColor.color}
                        onChange={newColor => updateGradientColor(index, newColor)}
                        className="input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </section>
  );
};
