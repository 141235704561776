import React from 'react';

export const IconReplace = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.53842 12.7596C6.53842 12.4981 6.30958 12.2692 6.04804 12.2692H1.14419C0.882655 12.2692 0.653809 12.4981 0.653809 12.7596V16.3558C0.653809 16.6173 0.882655 16.8461 1.14419 16.8461H6.04804C6.30958 16.8461 6.53842 16.6173 6.53842 16.3558V12.7596Z"
        fill="black"
      />
      <path
        d="M2.64789 7.69232H1.04597C0.719048 7.69232 0.555586 7.98655 0.75174 8.15001L3.36713 10.8635C3.49789 10.9615 3.69405 10.9615 3.82482 10.8635L6.4402 8.15001C6.63636 7.95386 6.47289 7.69232 6.14597 7.69232H4.60943C4.60943 6.0577 6.21136 4.42309 7.84597 4.42309V2.46155C4.90366 2.46155 2.64789 4.75001 2.64789 7.69232Z"
        fill="black"
      />
      <path
        d="M13.6653 7.13655C13.5346 7.03847 13.3384 7.03847 13.2077 7.13655L10.5923 9.85001C10.3961 10.0462 10.5596 10.3077 10.8865 10.3077H12.4557C12.4557 12.2692 11.1153 13.5769 9.15381 13.5769V15.5385C12.0961 15.5385 14.4173 13.25 14.4173 10.3077H16.0192C16.3461 10.3077 16.5096 10.0135 16.3134 9.85001L13.6653 7.13655Z"
        fill="black"
      />
      <path
        d="M16.346 1.64425C16.346 1.38272 16.1172 1.15387 15.8557 1.15387H10.9518C10.6903 1.15387 10.4614 1.38272 10.4614 1.64425V5.24041C10.4614 5.50195 10.6903 5.73079 10.9518 5.73079H15.8557C16.1172 5.73079 16.346 5.50195 16.346 5.24041V1.64425Z"
        fill="black"
      />
    </svg>
  );
};
