export const IconView = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6255 9C10.6255 10.1736 9.6741 11.125 8.50047 11.125C7.3269 11.125 6.37549 10.1736 6.37549 9C6.37549 7.82636 7.3269 6.875 8.50047 6.875C9.6741 6.875 10.6255 7.82636 10.6255 9Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50077 4.04166C5.32909 4.04166 2.64431 6.1262 1.7417 9C2.6443 11.8738 5.32909 13.9583 8.50077 13.9583C11.6724 13.9583 14.3572 11.8738 15.2598 9C14.3572 6.12623 11.6724 4.04166 8.50077 4.04166Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
