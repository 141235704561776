import React, { useState, useEffect } from 'react';
import styles from './Statistics.module.scss';
import { Header } from '../components';
import { useNavigate, useParams } from 'react-router-dom';
import { StatisticTable } from '../components/StatisticTable';
import { getStatisticsData, fetchProjectDetails, postStatsCsvData } from '../api/api';

const Statistics = () => {
  const navigate = useNavigate();
  const { encryptedProjectId } = useParams();
  const [statisticData, setStatisticData] = useState(null);
  const [projectData, setProjectData] = useState({
    project: null,
    persBlocks: [],
    targetButtons: [],
    projectLink: '',
    total_combinations: 0,
    total_size_gb: 0,
    styles: {},
    poster_url: null
  });

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetchProjectDetails(encryptedProjectId);
        const { project, pers_video_blocks, target_buttons, total_video_size } = response;

        if (project) {
          const { link, styles, poster_url } = project;
          const { total_combinations, total_size_gb } = total_video_size;

          setProjectData({
            project,
            persBlocks: pers_video_blocks,
            targetButtons: target_buttons,
            projectLink: link,
            total_combinations,
            total_size_gb,
            styles,
            poster_url: poster_url
          });
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных проекта:', error);
      }
    };

    fetchProjectData();
  }, [encryptedProjectId]);

  useEffect(() => {
    const getStatistics = async () => {
      const data = await getStatisticsData(encryptedProjectId);
      setStatisticData(data);
    };
    getStatistics();
  }, [encryptedProjectId]);

  const handleCsvClick = async () => {
    try {
      const data = await postStatsCsvData(encryptedProjectId);

      const blob = new Blob([data], { type: 'text/csv' });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'statistics.csv');
      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Ошибка при экспорте данных в CSV:', error);
    }
  };

  return (
    <div className={styles.statistics}>
      <Header />
      <div className={styles.statisticsNav}>
        <div className={styles.statisticsNavWrap}>
          <h1 className={styles.title}>Просмотр проекта “{projectData?.project?.name}” </h1>
          <div className={styles.data}>
            <h2 className={styles.dataSubtitle}>
              Фактический объем: {projectData?.total_size_gb || '0'} Гб
            </h2>
            <h2 className={styles.dataSubtitle}>
              Всего финальных видео: {projectData?.total_combinations || '0'} шт
            </h2>
          </div>
        </div>
        <section className={styles.management}>
          <button onClick={() => navigate('/projects')} className={styles.button}>
            Вернуться на главную
          </button>
          <button onClick={handleCsvClick} className={styles.button}>
            Экспортировать в CSV
          </button>
          <button
            onClick={() =>
              navigate(`/edit-project/${encryptedProjectId}`, {
                state: { encryptedProjectId, projectName: projectData.project?.name }
              })
            }
            className={styles.button}
          >
            Редактировать проект
          </button>
        </section>
      </div>
      <StatisticTable statisticData={statisticData} />
    </div>
  );
};

export default Statistics;
