import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import poster from '../../assets/images/poster.png';
import styles from './Player.module.scss';
import { IconPause, IconPlay } from '../Icons';

export const Player = ({ videoUrl, posterUrl, className }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPoster, setShowPoster] = useState(true);
  const [controlsVisible, setControlsVisible] = useState(true);

  const controlsTimeoutRef = React.useRef(null);

  useEffect(() => {
    if (controlsVisible) {
      controlsTimeoutRef.current = setTimeout(() => {
        setControlsVisible(false);
      }, 3000);
    }

    return () => {
      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }
    };
  }, [controlsVisible]);

  const handlePlayPause = () => {
    if (!isPlaying && showPoster) {
      setShowPoster(false);
    }
    setIsPlaying(!isPlaying);
    setControlsVisible(true);
  };

  const handleMouseMove = () => {
    setControlsVisible(true);
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
      controlsTimeoutRef.current = setTimeout(() => {
        setControlsVisible(false);
      }, 1000);
    }
  };

  return (
    <div className={styles.containerVideo} onMouseMove={handleMouseMove} onClick={handleMouseMove}>
      {showPoster ? (
        <img src={posterUrl || poster} alt="Poster" className={styles.poster} />
      ) : (
        <ReactPlayer
          url={videoUrl} // Передаем blob URL
          playing={isPlaying}
          className={className || styles.video}
          style={{ border: 'none', boxShadow: 'none' }}
          controls={true}
        />
      )}

      {controlsVisible && (
        <div className={styles.controls}>
          <button onClick={handlePlayPause}>{isPlaying ? <IconPause /> : <IconPlay />}</button>
        </div>
      )}
    </div>
  );
};
