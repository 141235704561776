import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Projects, EditProject, ViewProject, Login, NotFound, Statistics, Export } from './pages';
import { AuthProvider, MetaData } from './utils';
import './styles/global.css';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <MetaData title="Персикомм" description="Массовая генерация рекламных видеороликов" />
        <div className="app">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/edit-project/:projectId" element={<EditProject />} />
            <Route path="/view-project/:encryptedProjectId" element={<ViewProject />} />
            <Route path="/statistics/:encryptedProjectId" element={<Statistics />} />
            <Route path="/export/:encryptedProjectId" element={<Export />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
};

export default App;
