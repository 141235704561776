import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header, ManagementPanel, Popup, ProjectContent, VideoPlayer } from '../components';
import {
  fetchProjectDetails,
  deleteVideo,
  uploadVideo,
  createPersBlocks,
  changePersBlock,
  deleteAllTargetButtons,
  deleteTargetButtons,
  createTargetButton,
  deletePersBlock,
  changeVideo,
  massGenerateVideos,
  updatePersVideoName,
  updateTitleVideo,
  updateProjectLogo,
  updateProjectPoster,
  updateWatermark
} from '../api/api';
import { IconDoneProject } from '../components/Icons';
import styles from './EditProject.module.scss';

const EditProject = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const persVideoInputRef = useRef(null);
  const baseVideoInputRef = useRef(null);

  const { encryptedProjectId, initialName } = location.state || {};

  const [logoFile, setLogoFile] = useState(null);
  const [posterFile, setPosterFile] = useState(null);

  const [videos, setVideos] = useState([]);
  const [blobVideo, setBlobVideo] = useState(null);
  const [persBlocks, setPersBlocks] = useState([]);
  const [targetButtons, setTargetButtons] = useState([]);
  const [selectedPersVideo, setSelectedPersVideo] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [disableGen, setDisableGen] = useState(false);
  const [popupText, setPopupText] = useState('');
  const [project, setProject] = useState({
    name: initialName || '',
    is_ready: false,
    watermark: false
  });
  const [videoDetails, setVideoDetails] = useState({
    title: project.title || '',
    description: project.description || '',
    watermark: project.watermark || false,
    logo: null,
    poster: null,
    styles: null
  });
  const [isProjectSaved, setIsProjectSaved] = useState(false);
  const [totalVideoSize, setTotalVideoSize] = useState({ total_size_gb: 0, total_combinations: 0 });

  const isValidVideoArray = useCallback(videos => {
    return videos.every(item => {
      if (!item) {
        return false;
      }

      if (item.type === 'base') {
        if (item.url && item.url !== null) {
          return true;
        } else return false;
      }

      if (item.type === 'pers') {
        if (item.videos.length) {
          return true;
        } else return false;
      }

      if (Array.isArray(item.videos) && item.type !== 'base' && item.videos.length > 0) {
        return true;
      }

      return false;
    });
  }, []);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const projectData = await fetchProjectDetails(encryptedProjectId);

        const orderedVideos = orderVideos(projectData.videos, projectData.pers_video_blocks);

        setVideos(orderedVideos);
        setPersBlocks(projectData.pers_video_blocks);
        setTargetButtons(projectData.target_buttons);
        setProject({
          name: projectData.project.name,
          is_ready: projectData.project.is_ready,
          watermark: projectData.project.watermark
        });
        setVideoDetails({
          title: projectData.project.title,
          description: projectData.project.description,
          watermark: projectData.project.watermark,
          logo: projectData.project.logo_url,
          poster: projectData.project.poster_url,
          styles: projectData.project.styles
        });
        setTotalVideoSize(projectData.total_video_size);
      } catch (error) {
        console.error('Error loading project data:', error);
      }
    };

    fetchProjectData();
  }, [encryptedProjectId]);

  useEffect(() => {
    const isValid = isValidVideoArray(videos);

    if (isValid) {
      setDisableGen(false);
    } else {
      setDisableGen(true);
    }
  }, [isValidVideoArray, videos, videos.length]);

  const orderVideos = (videos, persVideoBlocks) => {
    const orderedVideos = [];

    // Инициализируем orderedVideos всеми pers_block
    persVideoBlocks.forEach(block => {
      orderedVideos.push({
        ...block,
        videos: [],
        type: 'pers_block'
      });
    });

    videos.forEach(video => {
      if (video.type === 'base') {
        orderedVideos.push({ ...video, videos: [] });
      } else if (video.type === 'pers' && video.pers_video_block_id) {
        const blockIndex = orderedVideos.findIndex(v => v.id === video.pers_video_block_id);
        if (blockIndex !== -1) {
          orderedVideos[blockIndex].videos.push(video);
        }
      }
    });

    return orderedVideos.sort((a, b) => a.position - b.position);
  };

  const handleFileChange = (e, videoType = 'base', videoIndex = null) => {
    const files = e.target.files;
    if (!files.length) return;

    const updatedVideos = [...videos];
    if (videoType === 'base') {
      if (baseVideoInputRef.current) {
        baseVideoInputRef.current.click();
      }

      handleBaseVideoChange(files, videoIndex, updatedVideos);
    } else {
      handlePersVideoChange(files, videoIndex, updatedVideos);
    }

    e.target.value = null;
  };

  const handleBaseVideoChange = (files, videoIndex, updatedVideos) => {
    const file = files[0];
    const videoURL = URL.createObjectURL(file);
    setBlobVideo(videoURL);

    if (videoIndex !== null) {
      updatedVideos[videoIndex] = {
        ...updatedVideos[videoIndex],
        url: videoURL,
        file,
        name: file.name.toLowerCase(),
        isUploaded: false // Флаг для проверки, было ли видео загружено на сервер
      };
    } else {
      updatedVideos.push({
        type: 'base',
        name: file.name.toLowerCase(),
        url: videoURL,
        file,
        isUploaded: false // Флаг для проверки, было ли видео загружено на сервер
      });
    }
    setVideos(updatedVideos);
  };

  const handlePersVideoChange = (files, videoIndex, updatedVideos) => {
    const newVideos = Array.from(files).map(file => ({
      type: 'pers',
      name: file.name.toLowerCase(),
      url: URL.createObjectURL(file),
      file,
      is_default: false,
      isUploaded: false, // Флаг для проверки, было ли видео загружено на сервер
      pers_video_block_id: updatedVideos[videoIndex]?.id || null
    }));

    if (videoIndex !== null) {
      updatedVideos[videoIndex].videos.push(...newVideos);
    } else {
      const newBlock = {
        type: 'pers_block',
        name: `Перс-видео ${updatedVideos.length + 1}`,
        position: updatedVideos.length,
        videos: newVideos
      };
      updatedVideos.push(newBlock);
    }

    setVideos(updatedVideos);
  };

  const handleDeleteVideo = async (videoIndex, type = 'base', persIndex = null) => {
    try {
      const updatedVideos = [...videos];

      if (type === 'base') {
        const videoToDelete = updatedVideos[videoIndex];
        if (videoToDelete.id) {
          await deleteVideo(encryptedProjectId, videoToDelete.id);
        }
        updatedVideos.splice(videoIndex, 1); // Удаляем видео из массива
      } else if (type === 'pers') {
        const videoBlock = updatedVideos[persIndex];
        const videoToDelete = videoBlock.videos[videoIndex];
        if (videoToDelete.id) {
          await deleteVideo(encryptedProjectId, videoToDelete.id);
        }
        videoBlock.videos.splice(videoIndex, 1); // Удаляем видео из блока
        if (videoBlock.videos.length === 0) {
          updatedVideos.splice(persIndex, 1); // Если все видео удалены, удаляем блок
          await deletePersBlock(encryptedProjectId, videoBlock.id);
        }
      }

      setVideos(updatedVideos); // Обновляем состояние после удаления
      alert('Видео успешно удалено');
    } catch (error) {
      console.error('Ошибка при удалении видео:', error);
      alert('Ошибка при удалении видео');
    }
  };

  const handleViewVideo = (url, isUploaded) => {
    let videoURL;
    if (isProjectSaved || isUploaded) {
      // Если проект сохранен или видео загружено, используем URL из локального хранилища
      videoURL = url.startsWith('https') ? url : `${url.replace('local_storage', '')}`; //https://api.persicom.ru
    } else {
      // Если проект еще не сохранен, используем URL, созданный с помощью URL.createObjectURL
      videoURL = `${url.replace('local_storage', '')}`; //https://api.persicom.ru
    }
    setShowPlayer(true);
    setSelectedPersVideo(videoURL);
    setBlobVideo(videoURL);
  };

  const handleUpdatePersName = async (index, newName, id) => {
    setVideos(prevVideos => {
      const updatedVideos = [...prevVideos];
      updatedVideos[index].name = newName;
      return updatedVideos;
    });

    await updatePersVideoName(encryptedProjectId, id, newName);
  };

  const collectDeletedVideos = () => {
    return videos.flatMap(video => {
      if (video.type === 'base' && video.isDeleted) {
        return [video];
      } else if (video.type === 'pers_block') {
        if (video.isDeleted) {
          return video.videos.concat(video);
        } else {
          return video.videos.filter(v => v.isDeleted);
        }
      }
      return [];
    });
  };

  const deleteVideos = async deletedVideos => {
    setPopupText('Удаление видео, подождите...');
    setShowPopup(true);

    for (const video of deletedVideos) {
      if (video.id !== undefined) {
        await deleteVideo(encryptedProjectId, video.id);
      }
    }

    setTimeout(() => {
      setShowPopup(false);
    }, 250);
  };

  const uploadBaseVideo = async (video, position) => {
    setPopupText('Загрузка основного видео, подождите...');
    setShowPopup(true);

    if (video.file) {
      const formData = createFormData(video, position);
      await uploadVideo(encryptedProjectId, formData);
    }
    setShowPopup(false);
  };

  const createFormData = (video, position) => {
    const formData = new FormData();
    formData.append('file', video.file);
    formData.append('type', video.type);
    formData.append('position', position);
    return formData;
  };

  const createPersBlock = async (video, position) => {
    setPopupText('Создание блока, подождите...');
    setShowPopup(true);

    const response = await createPersBlocks(encryptedProjectId, position, video);

    const newBlock = response;

    const updatedVideos = [...videos];
    const targetBlock = updatedVideos.find(v => v.name === video.name && v.position === position);
    if (targetBlock) {
      targetBlock.id = newBlock.id;
      targetBlock.videos = targetBlock.videos.map(v => ({
        ...v,
        pers_video_block_id: newBlock.id
      }));
      setVideos(updatedVideos);
    }

    setShowPopup(false);
    return newBlock;
  };

  const updatePersBlock = async (block, position) => {
    setPopupText('Обновление блока, подождите...');
    setShowPopup(true);

    const response = await changePersBlock(encryptedProjectId, position, block);

    setShowPopup(false);
    return response;
  };

  const createOrUpdatePersBlock = async (video, position) => {
    const existingBlock = video.id
      ? await updatePersBlock(video, position)
      : await createPersBlock(video, position);

    return existingBlock;
  };

  const uploadPersVideos = async (video, newBlock, position) => {
    setPopupText('Загрузка видео в блок, подождите...');
    setShowPopup(true);

    for (const persVideo of video.videos) {
      if (!persVideo.isDeleted && persVideo.file) {
        const formData = createFormData(persVideo, position);
        formData.append('isDefault', persVideo.is_default);
        formData.append('pers_video_block_id', newBlock.id);
        await uploadVideo(encryptedProjectId, formData);
      }
    }
    setShowPopup(false);
  };

  const uploadVideos = async () => {
    let position = 0;

    for (const video of videos) {
      if (video.isDeleted) continue;

      if (video.type === 'base') {
        await uploadBaseVideo(video, position);
      } else if (video.type === 'pers_block') {
        const filteredPersBlocks = videos.filter(v => v.type === 'pers_block');
        const updatedPersBlock = filteredPersBlocks.find(block => block.id === video.id);
        const newBlockData = updatedPersBlock ? { ...video, ...updatedPersBlock } : video;
        const newBlock = await createOrUpdatePersBlock(newBlockData, position);

        await uploadPersVideos(newBlockData, newBlock, position);
      }
      position++;
    }
  };

  const saveTargetButtons = async () => {
    try {
      setPopupText('Сохранение кнопки, подождите...');
      setShowPopup(true);

      await deleteAllTargetButtons(encryptedProjectId);
      for (const button of targetButtons) {
        await createTargetButton(encryptedProjectId, button);
      }
      setShowPopup(false);
    } catch (error) {
      console.error('Error saving target buttons:', error);
    }
  };

  const handleTargetButtonChange = (index, name, value) => {
    const updatedButtons = [...targetButtons];
    updatedButtons[index] = { ...updatedButtons[index], [name]: value };
    setTargetButtons(updatedButtons);
  };

  const handleDeletePersBlock = async blockIndex => {
    try {
      setPopupText('Удаление блока, подождите...');
      setShowPopup(true);

      const blockToDelete = videos[blockIndex];
      if (!blockToDelete.id) {
        console.error('Block ID not found for deletion');
        return;
      }
      await deletePersBlock(encryptedProjectId, blockToDelete);

      const updatedVideos = videos.filter((_, index) => index !== blockIndex);
      setVideos(updatedVideos);
    } catch (error) {
      console.error('Ошибка при удалении блока:', error);
      alert('Ошибка при удалении блока.');
    } finally {
      setShowPopup(false);
    }
  };

  const handleReplaceVideo = async (index, event) => {
    const file = event.target.files[0];
    if (!file) return;

    const updatedVideos = [...videos];
    const videoToUpdate = updatedVideos[index];

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', videoToUpdate.type);
    formData.append('position', videoToUpdate.position);
    formData.append('isDefault', videoToUpdate.is_default);
    formData.append('pers_video_block_id', videoToUpdate.pers_video_block_id || '');

    try {
      setPopupText('Идет замена видео, подождите...');
      setShowPopup(true);

      const response = await changeVideo(encryptedProjectId, formData, videoToUpdate.id);

      const updatedVideoData = response;
      updatedVideos[index] = updatedVideoData;
      setVideos(updatedVideos);
      alert('Видео успешно заменено');
    } catch (error) {
      console.error('Ошибка при замене видео:', error);
      alert('Ошибка при замене видео');
    } finally {
      setShowPopup(false);
    }
  };

  const handleDefaultVideo = async (selectedPersVideoIndex, videoIndex) => {
    const updatedVideos = [...videos];
    const currentBlock = updatedVideos[selectedPersVideoIndex];
    const selectedVideo = currentBlock.videos[videoIndex];

    const isCurrentlyDefault = selectedVideo.is_default;

    if (isCurrentlyDefault) {
      selectedVideo.is_default = false;
    } else {
      currentBlock.videos.forEach(video => {
        video.is_default = false;
      });
      selectedVideo.is_default = true;
    }

    setVideos(updatedVideos);

    try {
      setPopupText('Идет обновление видео, подождите...');
      setShowPopup(true);

      const formData = new FormData();
      formData.append('isDefault', selectedVideo.is_default);
      await changeVideo(encryptedProjectId, formData, selectedVideo.id);

      alert('Видео по умолчанию успешно обновлено');
    } catch (error) {
      console.error('Ошибка при обновлении видео:', error);
    } finally {
      setShowPopup(false);
    }
  };

  const handleMassGenerateVideos = async () => {
    try {
      setPopupText('Массовая генерация видео, подождите...');
      setShowPopup(true);

      await massGenerateVideos(encryptedProjectId);

      alert('Массовая генерация завершена');
    } catch (error) {
      console.error('Ошибка при массовой генерации видео:', error);
      alert('Ошибка при массовой генерации видео');
    } finally {
      setShowPopup(false);
    }
  };

  const handleAddPersVideo = async () => {
    const newBlockName = `Перс-видео ${videos.length + 1}`;
    const newPosition = videos.length;

    const newBlock = {
      type: 'pers_block',
      name: newBlockName,
      position: newPosition,
      id: null,
      is_twin: false,
      twin_block_id: null,
      videos: []
    };

    // Обновление локального массива videos новым блоком.
    const updatedVideos = [...videos, newBlock];
    setVideos(updatedVideos);
    setSelectedPersVideo(updatedVideos.length - 1);

    // Создание блока на сервере и обновление локального состояния полученным блоком
    const res = await createPersBlock(newBlock, newPosition);

    const block = { ...res, type: 'pers_block', videos: [] };

    setVideos(updatedVideos.map((v, i) => (i === newPosition ? block : v)));
    setSelectedPersVideo(newPosition);
    setIsOpen(true);
  };

  const handleDeleteTargetButton = async index => {
    try {
      setPopupText('Кнопка удаляется, подождите...');
      setShowPopup(true);

      const buttonId = targetButtons[index].id; // Получаем id кнопки
      if (buttonId) {
        await deleteTargetButtons(encryptedProjectId, buttonId);
      }
      const updatedButtons = targetButtons.filter((_, i) => i !== index); // Удаляем кнопку из списка
      setTargetButtons(updatedButtons);
      alert('Кнопка успешно удалена');
    } catch (error) {
      console.error('Ошибка при удалении кнопки:', error);
      alert('Ошибка при удалении кнопки');
    } finally {
      setShowPopup(false);
    }
  };

  const saveProjectLogo = async () => {
    if (logoFile) {
      await updateProjectLogo(encryptedProjectId, logoFile);
    }
  };

  const saveProjectPoster = async () => {
    if (posterFile) {
      await updateProjectPoster(encryptedProjectId, posterFile);
    }
  };

  const saveProjectVideos = async () => {
    const deletedVideos = collectDeletedVideos();
    await deleteVideos(deletedVideos);

    const persBlocksFromVideos = videos.filter(video => video.type === 'pers_block');
    const updatedPersBlocks = persBlocksFromVideos.map(block => {
      const existingBlock = persBlocks.find(pb => pb.id === block.id);

      if (existingBlock) {
        return { ...existingBlock, ...block };
      }

      return block;
    });

    setPersBlocks(updatedPersBlocks);

    for (const block of updatedPersBlocks) {
      if (block.id) {
        await updatePersBlock(block, block.position);
      } else {
        await createPersBlock(block, block.position);
      }
    }

    await uploadVideos();
  };

  const handleSaveProject = async () => {
    try {
      setPopupText('Сохранение проекта, пожалуйста, подождите...');
      setShowPopup(true);
      await saveProjectLogo();
      await saveProjectPoster();
      await saveProjectVideos();
      await saveTargetButtons();
      await updateTitleVideo(encryptedProjectId, videoDetails.title, videoDetails.description);
      await updateWatermark(encryptedProjectId, videoDetails.watermark);
      setIsProjectSaved(true);
      const projectData = await fetchProjectDetails(encryptedProjectId);
      setVideos(orderVideos(projectData.videos, projectData.pers_video_blocks));
      setTotalVideoSize(projectData.total_video_size);
      alert('Проект сохранен успешно!');
    } catch (error) {
      console.error('Ошибка при сохранении проекта:', error);
      alert('Ошибка при сохранении проекта.');
    } finally {
      setShowPopup(false);
    }
  };

  const handleAddBaseVideo = () => {
    const newBaseName = `Основное видео ${videos.length + 1}`;
    const newPosition = videos.length;

    const updatedVideos = [
      ...videos,
      {
        type: 'base',
        title: null,
        name: newBaseName,
        position: newPosition,
        url: null
      }
    ];

    setVideos(updatedVideos);
  };

  return (
    <section className={styles.edit}>
      <Header />
      <div className={styles.editHead}>
        <div className={styles.editHeadText}>
          <h1 className={styles.editHeadTextTitle}>
            Просмотр проекта “{project.name}” {project?.is_ready ? <IconDoneProject /> : null}
          </h1>
          <div style={{ display: 'flex', gap: 24 }}>
            <h2 className={styles.editHeadTextSubtitle}>
              Фактический объем:
              {totalVideoSize?.total_size_gb || '0'}
              Гб
            </h2>
            <h2 className={styles.editHeadTextSubtitle}>
              Всего финальных видео:
              {totalVideoSize?.total_combinations || '0'}
              шт
            </h2>
          </div>
        </div>
      </div>
      <section className={styles.management}>
        <button onClick={() => navigate('/projects')} className={styles.managementButton}>
          Закрыть без сохранения
        </button>
        <button onClick={handleSaveProject} className={styles.managementButton}>
          Сохранить
        </button>
        <button
          onClick={handleMassGenerateVideos}
          className={
            disableGen
              ? `${styles.managementButton} ${styles.disableBtn}`
              : `${styles.managementButton} ${styles.blue}`
          }
        >
          Генерация
        </button>
      </section>
      <ManagementPanel
        handleAddBaseVideo={handleAddBaseVideo}
        handleAddPersVideo={handleAddPersVideo}
      />
      <ProjectContent
        videos={videos}
        isOpen={isOpen}
        setVideos={setVideos}
        setIsOpen={setIsOpen}
        targetButtons={targetButtons}
        videoDetails={videoDetails}
        setVideoDetails={setVideoDetails}
        handleFileChange={handleFileChange}
        handleDeleteVideo={handleDeleteVideo}
        handleViewVideo={handleViewVideo}
        setSelectedPersVideo={setSelectedPersVideo}
        selectedPersVideo={selectedPersVideo}
        setTargetButtons={setTargetButtons}
        handleTargetButtonChange={handleTargetButtonChange}
        handleDeleteTargetButton={handleDeleteTargetButton}
        persVideoInputRef={persVideoInputRef}
        baseVideoInputRef={baseVideoInputRef}
        handleUpdatePersName={handleUpdatePersName}
        handleDeletePersBlock={handleDeletePersBlock}
        handleReplaceVideo={handleReplaceVideo}
        handleDefaultVideo={handleDefaultVideo}
        totalVideoSize={totalVideoSize}
        setLogoFile={setLogoFile}
        setPosterFile={setPosterFile}
      />
      <VideoPlayer
        showPlayer={showPlayer}
        setShowPlayer={setShowPlayer}
        selectedVideo={isProjectSaved ? selectedPersVideo : blobVideo}
      />
      <Popup showPopup={showPopup} text={popupText} />
    </section>
  );
};

export default EditProject;
