import React from 'react';

export const IconStatistic = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.31817 16.05H4.68183C4.78916 16.05 4.88235 15.9912 4.94694 15.9041C5.01154 15.817 5.05 15.6989 5.05 15.5705V12.4295C5.05 12.3011 5.01154 12.183 4.94694 12.0959C4.88235 12.0088 4.78916 11.95 4.68183 11.95H3.31817C3.21084 11.95 3.11765 12.0088 3.05306 12.0959C2.98846 12.183 2.95 12.3011 2.95 12.4295V15.5705C2.95 15.6987 2.98847 15.8168 3.05305 15.9039C3.11762 15.9911 3.2108 16.05 3.31817 16.05ZM3.68633 12.9085H4.31367V15.0906H3.68633V12.9085Z"
        fill="#231F20"
        stroke="black"
        strokeWidth="0.1"
      />
      <path
        d="M7.68183 16.05C7.89486 16.05 8.05 15.839 8.05 15.6025V7.39753C8.05 7.16101 7.89486 6.95 7.68183 6.95H6.31817C6.10514 6.95 5.95 7.16101 5.95 7.39753V15.6025C5.95 15.839 6.10514 16.05 6.31817 16.05H7.68183ZM6.68633 7.84505H7.31367V15.1549H6.68633V7.84505Z"
        fill="#231F20"
        stroke="black"
        strokeWidth="0.1"
      />
      <path
        d="M10.6818 16.05C10.8929 16.05 11.05 15.8493 11.05 15.6203V10.3797C11.05 10.1507 10.8929 9.95 10.6818 9.95H9.31822C9.10707 9.95 8.95 10.1507 8.95 10.3797V15.6203C8.95 15.8493 9.10707 16.05 9.31822 16.05H10.6818ZM9.68644 10.8093H10.3139V15.1907H9.68644V10.8093Z"
        fill="#231F20"
        stroke="black"
        strokeWidth="0.1"
      />
      <path
        d="M11.95 3.40625V15.5938C11.95 15.834 12.1043 16.05 12.3182 16.05H13.6818C13.8957 16.05 14.05 15.834 14.05 15.5938V3.40625C14.05 3.16601 13.8957 2.95 13.6818 2.95H12.3182C12.1043 2.95 11.95 3.16601 11.95 3.40625ZM12.6863 3.8625H13.3137V15.1375H12.6863V3.8625Z"
        fill="#231F20"
        stroke="black"
        strokeWidth="0.1"
      />
    </svg>
  );
};
