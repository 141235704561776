export const IconPlusCircle = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.9977 15.0518V26.9114" stroke="black" strokeWidth="1.5" strokeLinecap="square" />
      <path
        d="M26.9313 20.9852L15.0605 20.984"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 37.1875C29.9401 37.1875 37.1875 29.9401 37.1875 21C37.1875 12.0599 29.9401 4.8125 21 4.8125C12.0599 4.8125 4.8125 12.0599 4.8125 21C4.8125 29.9401 12.0599 37.1875 21 37.1875Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
};
