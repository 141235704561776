import axios from 'axios';

const API_URL = 'https://api.persicom.ru';

export const fetchProjects = async () => {
  const response = await axios.get(`${API_URL}/projects`);
  return response.data;
};

export const getProjectsStatistics = async () => {
  const response = await axios.get(`${API_URL}/projects/statistics`);
  return response.data;
};

export const fetchProject = async encryptedProjectId => {
  const response = await axios.get(`${API_URL}/projects/${encryptedProjectId}`);
  return response.data;
};

export const fetchProjectDetails = async encryptedProjectId => {
  const response = await axios.get(`${API_URL}/projects/${encryptedProjectId}/details`);
  return response.data;
};

export const createProject = async project => {
  const response = await axios.post(`${API_URL}/projects`, project);
  return response.data;
};

export const updateProject = async (encryptedProjectId, project) => {
  const response = await axios.put(`${API_URL}/projects/${encryptedProjectId}`, project);
  return response.data;
};

export const updateWatermark = async (encryptedProjectId, status) => {
  const response = await axios.put(`${API_URL}/projects/${encryptedProjectId}/watermark`, status, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return response.data;
};

export const updateProjectLogo = async (encryptedProjectId, file) => {
  const formData = new FormData();
  formData.append('logo', file);

  const response = await axios.put(
    `${API_URL}/projects/${encryptedProjectId}/update_logo`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  return response.data;
};

export const updateProjectPoster = async (encryptedProjectId, file) => {
  const formData = new FormData();
  formData.append('poster', file);

  const response = await axios.put(
    `${API_URL}/projects/${encryptedProjectId}/update_poster`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  return response.data;
};

export const uploadVideo = async (encryptedProjectId, formData) => {
  const response = await axios.post(`${API_URL}/projects/${encryptedProjectId}/videos`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const changeVideo = async (encryptedProjectId, formData, videoId) => {
  const response = await axios.put(
    `${API_URL}/projects/${encryptedProjectId}/videos/${videoId}`,
    formData
  );

  return response.data;
};

export const deleteVideo = async (encryptedProjectId, videoId) => {
  const response = await axios.delete(
    `${API_URL}/projects/${encryptedProjectId}/videos/${videoId}`
  );
  return response.data;
};

export const createTargetButton = async (encryptedProjectId, button) => {
  const response = await axios.post(`${API_URL}/projects/${encryptedProjectId}/target_buttons`, {
    ...button
  });
  return response.data;
};

export const deleteTargetButtons = async (encryptedProjectId, buttonId) => {
  const response = await axios.delete(
    `${API_URL}/projects/${encryptedProjectId}/target_buttons/${buttonId}`
  );
  return response.data;
};

export const deleteAllTargetButtons = async encryptedProjectId => {
  const response = await axios.delete(`${API_URL}/projects/${encryptedProjectId}/target_buttons`);
  return response.data;
};

export const generateDefaultVideo = async encryptedProjectId => {
  try {
    const response = await axios.post(
      `${API_URL}/projects/${encryptedProjectId}/generate_default_video`
    );
    return response.data;
  } catch (error) {
    console.error('Error generating default video:', error);
    throw error;
  }
};

export const saveProject = async (encryptedProjectId, videos, targetButtons) => {
  const formData = new FormData();
  videos.forEach((video, index) => {
    formData.append('videos', video.file, video.name);
    formData.append('type', video.type);
    formData.append('position', index);
    formData.append('isDefault', video.isDefault ? 'true' : 'false');
    formData.append('pers_video_name', video.pers_video_name || video.name);
  });

  targetButtons.forEach(button => {
    formData.append('target_buttons', JSON.stringify(button));
  });

  const response = await axios.post(`${API_URL}/projects/${encryptedProjectId}/save`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const deleteProject = async encryptedProjectId => {
  const response = await axios.delete(`${API_URL}/projects/${encryptedProjectId}`);
  return response.data;
};

export const updatePersVideoName = async (encryptedProjectId, videoId, newName) => {
  const response = await axios.put(
    `${API_URL}/projects/${encryptedProjectId}/update_name/${videoId}?pers_video_name=${newName}`
  );
  return response;
};

export const updateTitleVideo = async (encryptedProjectId, title, description) => {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('description', description);

  const response = await axios.put(
    `${API_URL}/projects/${encryptedProjectId}/update_title_description`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  return response.data;
};

export const updateBaseVideoTitle = async (video_id, title) => {
  const response = await axios.patch(`${API_URL}/projects/${video_id}/edit`, {
    title
  });

  return response.data;
};

export const generateVideo = async (encryptedProjectId, videos, persBlocks) => {
  const formData = new FormData(videos);

  persBlocks.forEach(block => {
    formData.append(`blocks`, formData.get(`block_${block.id}`));
  });

  const response = await axios.post(
    `${API_URL}/projects/${encryptedProjectId}/generate_video`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    }
  );

  return response.data;
};

export const generateCustomVideo = async params => {
  const response = await axios.post(
    `${API_URL}/projects/${params.project_id}/generate_custom_video`,
    params
  );

  return response.data;
};

export const massGenerateVideos = async encryptedProjectId => {
  const response = await axios.post(
    `${API_URL}/projects/${encryptedProjectId}/mass_generate_videos`
  );

  return response.data;
};

export const createPersBlocks = async (encryptedProjectId, position, block) => {
  const response = await axios.post(`${API_URL}/projects/${encryptedProjectId}/pers_video_blocks`, {
    name: block.name.toLowerCase(),
    position: position,
    is_twin: block.is_twin,
    twin_block_id: block.twin_block_id
  });

  return response.data;
};

export const changePersBlock = async (encryptedProjectId, position, block) => {
  const response = await axios.put(
    `${API_URL}/projects/${encryptedProjectId}/pers_video_blocks/${block.id}`,
    {
      name: block.name.toLowerCase(),
      position: position,
      is_twin: block.is_twin,
      twin_block_id: block.twin_block_id
    }
  );

  return response.data;
};

export const deletePersBlock = async (encryptedProjectId, block) => {
  const response = await axios.delete(
    `${API_URL}/projects/${encryptedProjectId}/pers_video_blocks/${block.id}`
  );

  return response.data;
};

export const changeStyles = async (encryptedProjectId, styles) => {
  const response = await axios.patch(`${API_URL}/projects/${encryptedProjectId}/styles`, styles);
  return response.data;
};

export const getStatisticsData = async encrypted_id => {
  const response = await axios.get(`${API_URL}/stats/stats_details/?encrypted_id=${encrypted_id}`);
  return response.data;
};

export const postStatsCsvData = async encrypted_id => {
  const response = await axios.post(
    `${API_URL}/stats/jsonstats_to_csv/?encrypted_id=${encrypted_id}`
  );
  return response.data;
};

export const importCsv = async file => {
  const formData = new FormData();

  formData.append(`file`, file[0]);

  const response = await axios.post(`${API_URL}/projects/import-csv`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return response.data;
};

export const leadExport = async lead => {
  const response = await axios.post(`${API_URL}/projects/lead-export`, lead, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return response.data;
};

export const generateLinks = async rows => {
  const response = await axios.post(`${API_URL}/projects/generate_links`, rows, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return response.data;
};

export const getAllPers = async project_id => {
  const response = await axios.get(
    `${API_URL}/projects/${project_id}/pers_video_blocks_with_videos`
  );

  return response.data;
};
