import React from 'react';

export const IconConnection = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.625 6.875V7.9375H7.4375C6.87391 7.9375 6.33341 8.16138 5.9349 8.5599C5.53638 8.95841 5.3125 9.49891 5.3125 10.0625V12.1875C5.3125 12.7511 5.53638 13.2916 5.9349 13.6901C6.33341 14.0886 6.87391 14.3125 7.4375 14.3125H12.75C13.3136 14.3125 13.8541 14.0886 14.2526 13.6901C14.6511 13.2916 14.875 12.7511 14.875 12.1875V10.0625C14.875 9.68949 14.7768 9.32306 14.5903 9.00003C14.4038 8.677 14.1355 8.40875 13.8125 8.22225V7.05563C15.0503 7.49338 15.9375 8.67381 15.9375 10.0614V12.1864C15.9375 13.0318 15.6017 13.8426 15.0039 14.4403C14.4061 15.0381 13.5954 15.3739 12.75 15.3739H7.4375C6.59212 15.3739 5.78137 15.0381 5.1836 14.4403C4.58583 13.8426 4.25 13.0318 4.25 12.1864V10.0625C4.25 9.21712 4.58583 8.40637 5.1836 7.8086C5.78137 7.21083 6.59212 6.875 7.4375 6.875H10.625Z"
        fill="black"
      />
      <path
        d="M6.375 11.125V10.0625H9.5625C10.1261 10.0625 10.6666 9.83862 11.0651 9.4401C11.4636 9.04159 11.6875 8.50109 11.6875 7.9375V5.8125C11.6875 5.24891 11.4636 4.70841 11.0651 4.3099C10.6666 3.91138 10.1261 3.6875 9.5625 3.6875H4.25C3.68642 3.6875 3.14591 3.91138 2.7474 4.3099C2.34888 4.70841 2.125 5.24891 2.125 5.8125V7.9375C2.12501 8.31051 2.2232 8.67694 2.40971 8.99997C2.59622 9.323 2.86447 9.59125 3.1875 9.77775V10.9444C2.56586 10.7244 2.0277 10.3172 1.64713 9.77866C1.26656 9.24015 1.06231 8.59691 1.0625 7.9375V5.8125C1.0625 4.96712 1.39833 4.15637 1.9961 3.5586C2.59387 2.96083 3.40462 2.625 4.25 2.625H9.5625C10.4079 2.625 11.2186 2.96083 11.8164 3.5586C12.4142 4.15637 12.75 4.96712 12.75 5.8125V7.9375C12.75 8.78288 12.4142 9.59363 11.8164 10.1914C11.2186 10.7892 10.4079 11.125 9.5625 11.125H6.375Z"
        fill="black"
      />
    </svg>
  );
};
