import React from 'react';
import styles from './StatisticTable.module.scss';
import { TABLE_TITLE } from './TableTitle';

export const StatisticTable = ({ statisticData }) => {
  return (
    <table className={styles.table}>
      <thead className={styles.tableTitle}>
        <tr className={styles.tableTitleTr}>
          {TABLE_TITLE.map(el => (
            <th key={el.id} scope="col" className={styles.tableTitleTrTh}>
              {el.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={styles.data}>
        {statisticData?.map((item, index) => (
          <tr key={item.id} className={styles.dataTr}>
            <td className={styles.dataTrTd}>{index + 1}</td>
            <td className={styles.dataTrTd}>{item.ip_address}</td>
            <td className={styles.dataTrTd}>{item.date}</td>
            <td className={styles.dataTrTd}>{item.time.slice(0, -3)}</td>
            <td className={styles.dataTrTd}>
              <a href="item.video_url">{item.video_url}</a>
            </td>
            <td className={styles.dataTrTd}>{item.pers_parameters.join()}</td>
            <td className={styles.dataTrTd}>{item.launched ? 'Да' : 'Нет'}</td>
            <td className={styles.dataTrTd}>{item.watch_lenght ? item.watch_lenght : 0}</td>
            <td className={styles.dataTrTd}>{item.watch_finish ? 'Да' : 'Нет'}</td>
            <td className={styles.dataTrTd}>{item.clicks ? item.clicks : ''}</td>
            <td className={styles.dataTrTd}>{item.visit_count ? item.visit_count : 0}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
