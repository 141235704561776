import { useNavigate, useLocation } from 'react-router-dom';
import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const local = localStorage.getItem('user');
    const data = local ? JSON.parse(local) : null;

    if (data && data.email === 'kljhgfsd@mail.ru' && data.password === 'hdf34278dsgcx') {
      setUser(data);
    } else if (!data && location.pathname !== '/') {
      navigate('/');
    }
  }, [navigate, location.pathname]);

  const login = ({ email, password }) => {
    if (email === 'kljhgfsd@mail.ru' && password === 'hdf34278dsgcx') {
      localStorage.setItem('user', JSON.stringify({ email, password }));
      setUser({ email, password });
      navigate('/projects');
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    navigate('/');
  };

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
