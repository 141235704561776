import React from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { PALETTE_PRESET } from '../../../../utils';
import styles from './Color.module.scss';
import './colorpicker.css';

export const Color = ({ color, setColor, updateBackground }) => {
  const handleChangeColor = color => {
    updateBackground(color);
  };

  return (
    <div style={{ width: 365 }}>
      <div className="colors">
        <HexColorPicker
          color={color}
          onChange={color => {
            setColor(color);
            handleChangeColor(color);
          }}
        />
        <div className={styles.preview}>
          <div className={styles.previewView} style={{ background: color }}></div>
          <div className={styles.previewInput}>
            #
            <HexColorInput
              color={color}
              onChange={color => {
                setColor(color);
                handleChangeColor(color);
              }}
              className="input"
            />
          </div>
        </div>
      </div>
      <ul className={styles.preset}>
        {PALETTE_PRESET.map(el => (
          <li
            key={el}
            className={styles.presetItem}
            onClick={() => {
              handleChangeColor(el);
              setColor(el);
            }}
            style={{ background: el }}
          ></li>
        ))}
      </ul>
    </div>
  );
};
