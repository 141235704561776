import React from 'react';
import styles from './Popup.module.scss';

export const Popup = ({ showPopup, text }) => {
  return (
    showPopup && (
      <div className={styles.popup}>
        <div className={styles.popupContent}>
          <p>{text?.length ? text : 'Сохранение проекта, пожалуйста, подождите...'}</p>
        </div>
      </div>
    )
  );
};
