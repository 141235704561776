export const GRADIENTS_PRESET = [
  'linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)',
  'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)',
  'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)',
  'linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%)',
  'linear-gradient(180deg, #FFFFFF 0%, #6284FF 50%, #FF0000 100%)',
  'linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%)',
  'linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)',
  'linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%)',
  'linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)',
  'linear-gradient(to top, #30cfd0 0%, #330867 100%)'
];

export const PALETTE_PRESET = [
  '#FFFFFF',
  '#C1C1C1',
  '#000000',
  '#3357FF',
  '#F2ED6F',
  '#FF33A1',
  '#A1FF33',
  '#33A1FF',
  '#FFAA33',
  '#33FFAA',
  '#FCCF31',
  '#360940',
  '#AA33FF',
  '#FF3333',
  '#3333FF',
  '#FF33FF',
  '#E2D1C3',
  '#FFFF33',
  '#FF6633',
  '#3366FF',
  '#66FF33',
  '#6633FF',
  '#33FFFF',
  '#654236',
  '#DA7635',
  '#FFDBDA'
];
