import React from 'react';
import styles from './ProjectCard.module.scss';
import {
  IconConnection,
  IconDelete,
  IconDoneProject,
  IconSettings,
  IconStatistic,
  IconView
} from '../Icons';

export const ProjectCard = React.memo(
  ({
    project,
    navigate,
    openEditPopup,
    handleLinkProject,
    handleDeleteProject,
    ensureAbsoluteURL
  }) => {
    return (
      <div key={project.encrypted_id} className={styles.projectCard}>
        <div className={styles.projectLogo}>
          {project.logo_url ? (
            <img
              src={project.logo_url}
              alt={`${project.name} logo`}
              className={styles.projectLogoImg}
            />
          ) : (
            <div className={styles.projectLogoEmpty}></div>
          )}
        </div>

        <div className={styles.projectDetails}>
          <div className={styles.projectDetailsWrapper}>
            <div className={styles.projectDetailsWrapperComplete}>
              <span
                onClick={() => openEditPopup(project)}
                className={styles.projectDetailsWrapperTitle}
              >
                {project.name}
              </span>
              <span>{project?.is_ready ? <IconDoneProject /> : null}</span>
            </div>
            <a
              href={ensureAbsoluteURL(project.link)}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.projectDetailsWrapperLink}
            >
              {project.link}
            </a>
          </div>
          <span className={styles.projectDetailsText}>
            Объём проекта: {project?.project_size || '0'} Гб
          </span>
          <span className={styles.projectDetailsText}>
            Кол-во видео: {project?.video_count || '0'} шт
          </span>
          <div className={styles.projectDetailsActions}>
            <button
              type="button"
              onClick={() => navigate(`/view-project/${project.encrypted_id}`)}
              className={styles.projectDetailsActionsButton}
              title="Просмотр проекта"
            >
              <IconView />
            </button>
            <button
              type="button"
              onClick={() => handleLinkProject(project, 'edit-project')}
              className={styles.projectDetailsActionsButton}
              title="Редактирование проекта"
            >
              <IconSettings />
            </button>
            <button
              type="button"
              onClick={() => handleLinkProject(project, 'statistics')}
              className={styles.projectDetailsActionsButton}
              title="Статистика проекта"
            >
              <IconStatistic />
            </button>
            <button
              type="button"
              onClick={() => handleLinkProject(project, 'export')}
              className={styles.projectDetailsActionsButton}
              title="Экспорт проекта"
            >
              <IconConnection />
            </button>
            <button
              type="button"
              onClick={() => handleDeleteProject(project.encrypted_id)}
              className={styles.projectDetailsActionsDelete}
              title="Удаление проекта"
            >
              <IconDelete />
            </button>
          </div>
        </div>
      </div>
    );
  }
);
