import React, { useState } from 'react';
import { Color } from './Color';
import { Gradient } from './Gradient';
import styles from './Background.module.scss';

export const Background = ({ updateBackground, handleSaveColors, onClose }) => {
  const [toggle, setToggle] = useState(false);
  const [color, setColor] = useState('#c1c1c1');

  return (
    <div className={styles.background}>
      <div className={styles.backgroundChoice}>
        <button
          onClick={() => setToggle(false)}
          className={
            !toggle ? `${styles.backgroundChoiceBtn} ${styles.active}` : styles.backgroundChoiceBtn
          }
        >
          Палитра
        </button>
        <button
          onClick={() => setToggle(true)}
          className={
            toggle ? `${styles.backgroundChoiceBtn} ${styles.active}` : styles.backgroundChoiceBtn
          }
        >
          Градиент
        </button>
      </div>

      <div className={styles.wrapper}>
        <div className={`${styles.wrapperContent} ${!toggle ? styles.show : styles.hide}`}>
          <Color color={color} setColor={setColor} updateBackground={updateBackground} />
        </div>
        <div className={`${styles.wrapperContent} ${toggle ? styles.show : styles.hide}`}>
          <Gradient updateBackground={updateBackground} />
        </div>
      </div>

      <div className={styles.management}>
        <button
          onClick={() => {
            handleSaveColors();
            onClose();
          }}
          className={styles.managementButton}
        >
          Сохранить
        </button>
        <button onClick={onClose} className={`${styles.managementButton} ${styles.blue}`}>
          Закрыть без сохранения
        </button>
      </div>
    </div>
  );
};
