export const TABLE_TITLE = [
  { id: 1, title: '№' },
  { id: 2, title: 'IP посетителя' },
  { id: 3, title: 'Дата' },
  { id: 4, title: 'Время' },
  { id: 5, title: 'Ссылка на видео' },
  { id: 6, title: 'Параметры персов' },
  { id: 7, title: 'Запущено' },
  { id: 8, title: 'Просмотрено' },
  { id: 9, title: 'Просмотрено до конца' },
  { id: 10, title: 'Клики на ЦД' },
  { id: 11, title: 'Повторных посещений' }
];
