import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import styles from './PersVideoEditor.module.scss';
import { IconDelete, IconEdit, IconPlusCircle, IconView } from '../Icons';

export const PersVideoEditor = ({
  videos,
  onClose,
  selectedPersVideo,
  handleFileChange,
  persVideoInputRef,
  handleDefaultVideo,
  handleDeleteVideo,
  handleViewVideo
}) => {
  return (
    <>
      {selectedPersVideo !== null &&
        videos[selectedPersVideo] &&
        videos[selectedPersVideo].type === 'pers_block' && (
          <div className={styles.block}>
            <h2>{videos[selectedPersVideo].name}</h2>
            <button
              onClick={() => persVideoInputRef.current && persVideoInputRef.current.click()}
              className={styles.button}
            >
              <IconPlusCircle /> Добавить видео
            </button>
            <input
              type="file"
              id={`upload-pers-video-${selectedPersVideo}`}
              ref={persVideoInputRef}
              style={{ display: 'none' }}
              multiple
              onChange={e => handleFileChange(e, 'pers', selectedPersVideo)}
            />
            <div className={styles.list}>
              {videos[selectedPersVideo].videos.map((video, index) => (
                <div key={index} className={styles.listItem}>
                  <div className={styles.listItemWrapper}>
                    <FontAwesomeIcon
                      icon={faStar}
                      className={`fa-star ${video.is_default ? `${styles.def}` : ''}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDefaultVideo(selectedPersVideo, index)}
                    />
                    <div className={styles.listItemWrapperName}>{video.name}</div>
                  </div>
                  <div className={styles.buttons}>
                    <button className={styles.buttonIcon}>
                      <IconEdit />
                    </button>
                    <button
                      onClick={() => {
                        handleViewVideo(video.url);
                        onClose();
                      }}
                      className={styles.buttonIcon}
                    >
                      <IconView />
                    </button>
                    <button
                      onClick={() => handleDeleteVideo(index, 'pers', selectedPersVideo)}
                      className={styles.buttonIcon}
                    >
                      <IconDelete />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
    </>
  );
};
