import React, { useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ShareSettings.module.scss';

export const ShareSettings = ({
  pers,
  table,
  setFullRows,
  setMissingPersCells,
  setSelectedColumns,
  handleCheckPers,
  hanleGenerateClick
}) => {
  const persWithLink = [...pers, { id: 'linkToVideo', name: 'Ссылка на перс видео' }];
  const tableHeaders = table?.length ? Object.keys(table[0]) : [];

  const [selectedPers, setSelectedPers] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState({});

  const handleSelectChange = (persId, selectedHeader) => {
    setSelectedPers(prev => ({
      ...prev,
      [persId]: selectedHeader
    }));
    setIsDropdownOpen(prev => ({
      ...prev,
      [persId]: false
    }));
    setSelectedColumns(prev => ({ ...prev, [persId]: selectedHeader }));
  };

  const toggleDropdown = persId => {
    setIsDropdownOpen(prev => ({
      ...prev,
      [persId]: !prev[persId]
    }));
  };

  const checkPersAvailability = (selectedPers, table) => {
    const missingPers = [];

    Object.entries(selectedPers).forEach(([persId, selectedHeader]) => {
      const persData = pers.find(person => person.id.toString() === persId);

      if (!persData) {
        missingPers.push({ persId, error: 'Перс не найден' });
        return;
      }

      table.forEach((row, rowIndex) => {
        const value = row[selectedHeader]?.trim().toLowerCase();

        const matchedVideo = persData.videos.find(
          video => video.name.replace('.mp4', '').toLowerCase() === value
        );

        if (!matchedVideo) {
          missingPers.push({ rowIndex, column: selectedHeader, expected: persData, found: value });
        }
      });
    });

    return missingPers;
  };

  const collectPersToCheck = () => {
    if (Object.keys(selectedPers).length === persWithLink.length) {
      const matchedRows = [];
      const result = {};

      table.forEach((row, rowIndex) => {
        const rowMatches = [];
        const phoneValue = row['Телефон']?.trim();

        let allMatches = true;

        Object.entries(selectedPers).forEach(([persId, selectedHeader]) => {
          if (persId === 'linkToVideo') return;

          const persData = pers.find(person => person.id.toString() === persId);

          const videoMatch = persData?.videos.find(
            video =>
              video.name.replace('.mp4', '').toLowerCase() ===
              row[selectedHeader]?.trim().toLowerCase()
          );

          if (videoMatch) {
            rowMatches.push(videoMatch.name.replace('.mp4', '').toLowerCase());
          } else {
            allMatches = false;
          }
        });

        if (allMatches) {
          matchedRows.push(row);
          result[phoneValue || rowIndex] = rowMatches;
        }
      });

      handleCheckPers(result);
      setFullRows(matchedRows);

      const missingPers = checkPersAvailability(selectedPers, table);
      setMissingPersCells(missingPers.length > 0 ? missingPers : []);
    } else {
      alert('Выберите, пожалуйста, все персы!');
    }
  };

  return (
    <div className={styles.share}>
      <h2 className={styles.shareTitle}>Настройка проекта рассылки</h2>
      <div className={styles.shareSelects}>
        {persWithLink.map(({ id, name }) => (
          <div key={id} className={styles.shareSelectsItem}>
            <h3 className={styles.shareSelectsItemTitle}>{name}</h3>
            <div className={styles.dropdown}>
              <div className={styles.dropdownSelect} onClick={() => toggleDropdown(id)}>
                {selectedPers[id] ? (
                  selectedPers[id]
                ) : (
                  <div className={styles.dropdownMenuEmpty}>
                    Не выбран
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                )}
              </div>
              {isDropdownOpen[id] && (
                <div className={styles.dropdownMenu}>
                  {tableHeaders.map(header => (
                    <div
                      key={header}
                      className={styles.dropdownMenuItem}
                      onClick={() => handleSelectChange(id, header)}
                    >
                      {header}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className={styles.shareCheck}>
        <button className={styles.shareCheckBtn} onClick={collectPersToCheck}>
          Проверить наличие персов
        </button>
        <button className={styles.shareCheckBtn} onClick={hanleGenerateClick}>
          Сгенерировать ссылки
        </button>
      </div>
    </div>
  );
};
