import React from 'react';
import styles from './ManagementPanel.module.scss';
import { IconPlusCircle } from '../Icons';

export const ManagementPanel = ({ handleAddBaseVideo, handleAddPersVideo }) => (
  <section className={styles.management}>
    <div className={styles.managementButtons}>
      <button onClick={handleAddBaseVideo} className={styles.managementButtonsButton}>
        <IconPlusCircle /> Основное видео
      </button>
      <button onClick={handleAddPersVideo} className={styles.managementButtonsButton}>
        <IconPlusCircle /> Перс видео
      </button>
    </div>
  </section>
);
