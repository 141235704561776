import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';

export const Modal = ({ isOpen, onClose, children, hideCross }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 500); // Задержка закрытия для завершения анимации

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    isVisible ? (
      <div className={`modal-overlay ${isOpen ? 'open' : 'close'}`} onClick={handleOverlayClick}>
        <div className="modal-content">
          {!hideCross && (
            <button className="close-button" onClick={onClose}>
              &times;
            </button>
          )}
          <div className={`modal-children ${isOpen ? 'fade-in' : 'fade-out'}`}>{children}</div>
        </div>
      </div>
    ) : null,
    document.getElementById('modal-root')
  );
};
