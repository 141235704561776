import React, { useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { PALETTE_PRESET } from '../../../utils';
import styles from './Buttons.module.scss';
import styled from 'styled-components';
import './buttonspicker.css';

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 364px;
  margin: auto;
  border: 1px solid #1480de;
  border-radius: 12px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  color: ${({ btn }) => btn.color || '#000000'};
  background-color: ${({ btn }) => btn.backgroundColor || '#ffffff'};
  border-color: ${({ btn }) => btn.borderColor || '#1480de'};
  font-weight: ${({ btn }) => btn.fontWeight || 400};

  &:hover {
    color: ${({ btn }) => btn.hover?.color || btn.color};
    background-color: ${({ btn }) => btn.hover?.backgroundColor || btn.backgroundColor};
    border-color: ${({ btn }) => btn.hover?.borderColor || btn.borderColor};
  }

  &:active {
    color: ${({ btn }) => btn.active?.color || btn.color};
    background-color: ${({ btn }) => btn.active?.backgroundColor || btn.backgroundColor};
    border-color: ${({ btn }) => btn.active?.borderColor || btn.borderColor};
  }
`;

export const Buttons = ({
  btnColor,
  setBtnColor,
  onClose,
  handleSaveColors,
  selectedColorType,
  setSelectedColorType,
  colorPickerValue,
  setColorPickerValue
}) => {
  const [selectedState, setSelectedState] = useState('inactive');
  const [btn, setBtn] = useState(btnColor || {});

  const updateBtnColor = newColor => {
    if (selectedState === 'inactive') {
      setBtnColor({
        ...btnColor,
        [selectedColorType]: newColor
      });
      setBtn({
        ...btnColor,
        [selectedColorType]: newColor
      });
    } else {
      setBtnColor({
        ...btnColor,
        [selectedState]: {
          ...btnColor[selectedState],
          [selectedColorType]: newColor
        }
      });
      setBtn({
        ...btnColor,
        [selectedState]: {
          ...btnColor[selectedState],
          [selectedColorType]: newColor
        }
      });
    }
  };

  return (
    <div className={styles.palete}>
      <section className={`${styles.choice} colors`}>
        <div className={styles.choiceDiv}>
          <div>
            <h3 className={styles.title}>Жирность шрифта</h3>
            <ul className={styles.choiceDivBtns}>
              {[
                { name: 'Тонкий', value: 100 },
                { name: 'Обычный', value: 400 },
                { name: 'Жирный', value: 700 }
              ].map(el => (
                <li
                  key={el.name}
                  className={`${styles.btn} ${btn.fontWeight === el.value ? styles.active : ''}`}
                  onClick={() => {
                    setBtnColor(
                      selectedState === 'inactive'
                        ? {
                            ...btnColor,
                            fontWeight: el.value
                          }
                        : {
                            ...btnColor,
                            [selectedState]: {
                              ...btnColor[selectedState],
                              fontWeight: el.value
                            }
                          }
                    );
                    setBtn(
                      selectedState === 'inactive'
                        ? {
                            ...btnColor,
                            fontWeight: el.value
                          }
                        : {
                            ...btnColor,
                            [selectedState]: {
                              ...btnColor[selectedState],
                              fontWeight: el.value
                            }
                          }
                    );
                  }}
                >
                  {el.name}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className={styles.title}>Выберите состояние кнопки</h3>
            <ul className={styles.types}>
              {['inactive', 'hover', 'active'].map(state => (
                <li
                  key={state}
                  className={`${styles.btn} ${selectedState === state ? styles.active : ''}`}
                  onClick={() => setSelectedState(state)}
                >
                  {state === 'inactive' ? 'Неактивная' : state === 'hover' ? 'Курсор' : 'Нажатая'}
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className={styles.title}>Выберите тип цвета</h3>
            <ul className={styles.types}>
              {['color', 'backgroundColor', 'borderColor'].map(type => (
                <li
                  key={type}
                  className={`${styles.btn} ${selectedColorType === type ? styles.active : ''}`}
                  onClick={() => setSelectedColorType(type)}
                >
                  {type === 'color' ? 'Текст' : type === 'backgroundColor' ? 'Фон' : 'Рамка'}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={styles.paleteDiv}>
          <HexColorPicker
            color={colorPickerValue}
            onChange={newColor => {
              setColorPickerValue(newColor);
              updateBtnColor(newColor);
            }}
          />
          <div className={styles.preview}>
            <div className={styles.previewView} style={{ backgroundColor: colorPickerValue }}></div>
            <div className={styles.previewInput}>
              #
              <HexColorInput
                alpha
                color={colorPickerValue}
                onChange={newColor => {
                  setColorPickerValue(newColor);
                  updateBtnColor(newColor);
                }}
                className="input"
              />
            </div>
          </div>
        </div>
      </section>

      <ul className={styles.preset}>
        {PALETTE_PRESET.map((el, idx) => (
          <li
            key={idx}
            className={styles.presetItem}
            style={{ backgroundColor: el }}
            onClick={() => {
              setColorPickerValue(el);
              updateBtnColor(el);
            }}
          ></li>
        ))}
      </ul>

      <StyledButton btn={btn}>Пример кнопки</StyledButton>

      <div className={styles.management}>
        <div>
          <button
            onClick={() => {
              handleSaveColors();
              onClose();
            }}
            className={styles.managementButton}
          >
            Сохранить
          </button>
          <button onClick={onClose} className={`${styles.managementButton} ${styles.blue}`}>
            Закрыть без сохранения
          </button>
        </div>
      </div>
    </div>
  );
};
