import React from 'react';

export const IconSwap = () => {
  return (
    <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.333 6.47998C23.3328 6.28032 23.3874 6.08444 23.4907 5.91356C23.594 5.74269 23.7421 5.60335 23.9189 5.51065C24.0957 5.41795 24.2946 5.37543 24.4939 5.38768C24.6931 5.39994 24.8853 5.46652 25.0494 5.58019L31.5127 10.0558C31.658 10.1565 31.7768 10.2908 31.8588 10.4474C31.9408 10.604 31.9836 10.7781 31.9836 10.9549C31.9836 11.1316 31.9408 11.3058 31.8588 11.4623C31.7768 11.6189 31.658 11.7533 31.5127 11.8539L25.0494 16.3296C24.8853 16.4432 24.6931 16.5098 24.4939 16.5221C24.2946 16.5343 24.0957 16.4918 23.9189 16.3991C23.7421 16.3064 23.594 16.1671 23.4907 15.9962C23.3874 15.8253 23.3328 15.6294 23.333 15.4298V12.4154H5.83296C5.44618 12.4154 5.07525 12.2618 4.80176 11.9883C4.52827 11.7148 4.37462 11.3438 4.37462 10.9571C4.37462 10.5703 4.52827 10.1994 4.80176 9.92587C5.07525 9.65238 5.44618 9.49873 5.83296 9.49873H23.333V6.47998ZM9.94983 20.1635C10.114 20.0498 10.3061 19.9833 10.5054 19.971C10.7047 19.9588 10.9035 20.0013 11.0804 20.094C11.2572 20.1867 11.4053 20.326 11.5086 20.4969C11.6119 20.6678 11.6664 20.8637 11.6663 21.0633V24.0821H29.1663C29.5531 24.0821 29.924 24.2357 30.1975 24.5092C30.471 24.7827 30.6246 25.1536 30.6246 25.5404C30.6246 25.9272 30.471 26.2981 30.1975 26.5716C29.924 26.8451 29.5531 26.9987 29.1663 26.9987H11.6663V30.0131C11.6664 30.2128 11.6119 30.4087 11.5086 30.5795C11.4053 30.7504 11.2572 30.8897 11.0804 30.9824C10.9035 31.0751 10.7047 31.1177 10.5054 31.1054C10.3061 31.0931 10.114 31.0266 9.94983 30.9129L3.4865 26.4373C3.34121 26.3366 3.22247 26.2022 3.14047 26.0457C3.05846 25.8891 3.01562 25.715 3.01562 25.5382C3.01562 25.3615 3.05846 25.1873 3.14047 25.0308C3.22247 24.8742 3.34121 24.7398 3.4865 24.6391L9.94983 20.1635Z"
        fill="black"
      />
    </svg>
  );
};
