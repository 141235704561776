import React from 'react';
import styles from './LoadingPopup.module.scss';

export const LoadingPopup = ({ loadingText }) => {
  return (
    <div className={styles.loadingPopup}>
      <div className={styles.loadingPopupInner}>
        <p className={styles.loadingText}>{loadingText ? loadingText : 'Загрузка...'}</p>
      </div>
    </div>
  );
};
