import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  fetchProjects,
  createProject,
  deleteProject,
  updateProject,
  getProjectsStatistics
} from '../api/api';
import { IconPlusCircle } from '../components/Icons';
import { Header, LoadingPopup } from '../components';
import styles from './Projects.module.scss';
import { ProjectCard } from '../components/ProjectCard';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [statistics, setStatistics] = useState(null);
  const [newProject, setNewProject] = useState({ name: '', link: '' });
  const [status, setStatus] = useState({
    isPopup: false,
    isLoading: false,
    isError: false,
    loadingText: ''
  });
  const [editProject, setEditProject] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getProjects = async () => {
      const projects = await fetchProjects();
      const stats = await getProjectsStatistics();
      setProjects(projects);
      setStatistics(stats);
      setFilteredProjects(projects);
    };
    getProjects();
  }, []);

  const handleCreateProject = async () => {
    const { name, link } = newProject;
    if (name.trim() && link.trim()) {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('link', link);

      try {
        const createdProject = await createProject(formData);
        setProjects(prevProjects => [...prevProjects, createdProject]);
        setFilteredProjects(prevProjects => [...prevProjects, createdProject]);
        setNewProject({ name: '', link: '' });
        setStatus({ ...status, isPopup: false, isError: false });
      } catch (error) {
        console.error('Error creating project:', error);
        setStatus({ ...status, isError: true });
      }
    } else {
      setStatus({ ...status, isError: true });
    }
  };

  const handleUpdateProject = async () => {
    setStatus({ ...status, isLoading: true });

    const { name, link } = newProject;

    if (editProject) {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('link', link);

      try {
        const updatedProject = await updateProject(editProject.encrypted_id, formData);
        setProjects(
          projects.map(project =>
            project.encrypted_id === editProject.encrypted_id ? updatedProject : project
          )
        );
        setEditProject(null);
        setNewProject({ name: '', link: '' });
        setStatus({ ...status, isPopup: false, isError: false, isLoading: false });
      } catch (error) {
        console.error('Error updating project:', error);
        setStatus({ ...status, isError: true });
      }
    } else {
      console.error('No project selected for editing');
    }
  };

  const handleDeleteProject = async projectId => {
    if (window.confirm('Вы уверены, что хотите удалить этот проект?')) {
      try {
        await deleteProject(projectId);
        const updatedProjects = projects.filter(project => project.encrypted_id !== projectId);
        setProjects(updatedProjects);
        setFilteredProjects(updatedProjects);
        alert('Проект успешно удален');
      } catch (error) {
        console.error('Ошибка при удалении проекта:', error);
        alert('Ошибка при удалении проекта');
      }
    }
  };

  const cancelNewProject = () => {
    setEditProject(null);
    setNewProject({ name: '', link: '' });
    setStatus({ ...status, isPopup: false, isError: false });
  };

  const ensureAbsoluteURL = url => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }
    return url;
  };

  const openEditPopup = project => {
    setEditProject(project);
    setNewProject({ name: project.name, link: project.link });
    setStatus({ ...status, isPopup: true });
  };

  const handleLinkProject = (project, page) => {
    navigate(`/${page}/${project.encrypted_id}`, {
      state: { encryptedProjectId: project.encrypted_id }
    });
  };

  const handleFilter = (status = 'all') => {
    if (status !== 'all') {
      const fp = projects.filter(p => p.is_ready === status);
      setFilteredProjects(fp);
    } else {
      setFilteredProjects(projects);
    }
  };

  const { isPopup, isError, isLoading, loadingText } = status;

  return (
    <div className={styles.projects}>
      <Header />
      <section className={styles.head}>
        <button
          onClick={() => setStatus({ ...status, isPopup: true })}
          className={styles.headButton}
        >
          <IconPlusCircle />
          <div className={styles.headButtonText}>
            Создать новый проект
            <span className={styles.headButtonTextSpan}>Создание проекта с нуля</span>
          </div>
        </button>
        <div className={styles.statisticsStyles}>
          <span className={styles.statisticsStylesText}>
            Проектов в работе: {statistics?.projects_in_progress_count || '0'} шт
          </span>
          <span className={styles.statisticsStylesText}>
            Занятый объем: {statistics?.total_project_size_gb || '0'}Гб
          </span>
          <span className={styles.statisticsStylesText}>
            Всего видео: {statistics?.total_final_videos_count || '0'} шт
          </span>
        </div>
      </section>
      <div className={styles.statistics}>
        <button type="button" className={styles.button} onClick={() => handleFilter('all')}>
          Все проекты
        </button>
        <button type="button" className={styles.button} onClick={() => handleFilter(true)}>
          Готовые
        </button>
        <button type="button" className={styles.button} onClick={() => handleFilter(false)}>
          Редактируемые
        </button>
      </div>
      <section className={styles.project}>
        {filteredProjects.map(project => (
          <ProjectCard
            key={project.encrypted_id}
            project={project}
            navigate={navigate}
            openEditPopup={openEditPopup}
            handleLinkProject={handleLinkProject}
            handleDeleteProject={handleDeleteProject}
            ensureAbsoluteURL={ensureAbsoluteURL}
          />
        ))}
      </section>

      {isPopup && (
        <div className={styles.popup}>
          <div className={styles.popupContent}>
            <h2 className={styles.popupTitle}>
              {editProject ? 'Редактирование проекта' : 'НОВЫЙ ПРОЕКТ'}
            </h2>
            <label className={styles.popupLabel}>Название проекта</label>
            <input
              type="text"
              value={newProject.name || ''}
              onChange={e => setNewProject({ ...newProject, name: e.target.value })}
              className={styles.popupInput}
            />
            <label className={styles.popupLabel}>Ссылка на сайт проекта</label>
            <input
              type="text"
              value={newProject.link || ''}
              onChange={e => setNewProject({ ...newProject, link: e.target.value })}
              className={styles.popupInput}
            />
            {isError && <p className={styles.popupError}>Пожалуйста, заполните все поля</p>}
            <div className={styles.popupButtons}>
              <button
                type="submit"
                onClick={editProject ? handleUpdateProject : handleCreateProject}
                className={styles.button}
              >
                {editProject ? 'Сохранить изменения' : 'Создать'}
              </button>
              <button type="button" onClick={cancelNewProject} className={styles.button}>
                Отмена
              </button>
            </div>
          </div>
        </div>
      )}

      {isLoading && <LoadingPopup loadingText={loadingText} />}
    </div>
  );
};

export default Projects;
