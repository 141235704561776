export const IconDoneProject = () => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_410_414)">
        <path
          d="M18.2377 11.1932C18.1089 11.1622 17.9913 11.0926 17.8989 10.9927C17.8066 10.8928 17.7434 10.7669 17.7169 10.63C17.3869 8.96278 16.6663 7.53233 15.6037 6.44825C14.3669 5.19046 12.7156 4.49169 11 4.50007C9.47461 4.50007 8.06781 5.03144 6.93172 6.03643C6.15591 6.7296 5.53886 7.59992 5.12703 8.58187C5.08261 8.68722 5.01514 8.77987 4.93019 8.85219C4.84524 8.9245 4.74525 8.97441 4.63848 8.99778C3.47832 9.25323 2.44406 9.78596 1.6659 10.5373C0.575352 11.5855 0 13.0191 0 14.6818C0 16.3182 0.617891 17.8127 1.7398 18.8905C2.82047 19.9286 4.27797 20.5 5.84375 20.5H17.0156C18.4065 20.5 19.6036 20.1105 20.4785 19.3741C21.4741 18.5355 22 17.2905 22 15.7727C22 13.1559 20.1953 11.6555 18.2377 11.1932ZM14.147 10.7869L9.515 16.605C9.45157 16.6847 9.37271 16.749 9.28375 16.7936C9.19479 16.8382 9.09782 16.8621 8.99938 16.8637H8.98777C8.89155 16.8637 8.79639 16.8423 8.70846 16.801C8.62053 16.7596 8.54178 16.6992 8.4773 16.6237L6.50504 14.3114C6.25711 14.0209 6.25496 13.5691 6.51793 13.2932C6.58395 13.2238 6.6626 13.1693 6.74913 13.133C6.83566 13.0967 6.92826 13.0793 7.02131 13.082C7.11436 13.0847 7.20593 13.1073 7.29046 13.1486C7.37499 13.1898 7.45073 13.2487 7.51309 13.3219L8.96973 15.03L13.0947 9.84823C13.1529 9.77517 13.2241 9.71494 13.3043 9.67099C13.3845 9.62704 13.472 9.60023 13.562 9.59208C13.652 9.58394 13.7426 9.59462 13.8287 9.62352C13.9148 9.65241 13.9946 9.69896 14.0637 9.7605C14.1327 9.82205 14.1897 9.89738 14.2312 9.98219C14.2728 10.067 14.2981 10.1597 14.3058 10.2548C14.3135 10.35 14.3034 10.4459 14.2761 10.5369C14.2488 10.628 14.2048 10.7124 14.1466 10.7855L14.147 10.7869Z"
          fill="#099CFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_410_414">
          <rect width="22" height="20" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
