import React from 'react';

export const IconEdit = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.02148 12.6429L2.66178 12.2956L2.52148 12.4409V12.6429H3.02148ZM12.3318 3L12.6915 2.6527C12.5973 2.55512 12.4675 2.5 12.3318 2.5C12.1962 2.5 12.0663 2.55512 11.9721 2.6527L12.3318 3ZM17.5042 8.35714L17.8639 8.70444C18.051 8.51069 18.051 8.20359 17.8639 8.00985L17.5042 8.35714ZM8.1939 18V18.5H8.40616L8.5536 18.3473L8.1939 18ZM3.02148 18H2.52148C2.52148 18.2761 2.74534 18.5 3.02148 18.5V18ZM3.38119 12.9902L12.6915 3.3473L11.9721 2.6527L2.66178 12.2956L3.38119 12.9902ZM11.9721 3.3473L17.1445 8.70444L17.8639 8.00985L12.6915 2.6527L11.9721 3.3473ZM17.1445 8.00985L7.8342 17.6527L8.5536 18.3473L17.8639 8.70444L17.1445 8.00985ZM8.1939 17.5H3.02148V18.5H8.1939V17.5ZM3.52148 18V12.6429H2.52148V18H3.52148ZM8.86868 6.56158L14.0411 11.9187L14.7605 11.2241L9.58808 5.86699L8.86868 6.56158ZM10.7801 18.5H18.0215V17.5H10.7801V18.5Z"
        fill="black"
      />
    </svg>
  );
};
