import React from 'react';

export const IconRotate = () => {
  return (
    <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_628_1863)">
        <path
          d="M30.625 33.3125H17.5C16.92 33.3118 16.364 33.0812 15.9539 32.6711C15.5438 32.261 15.3132 31.705 15.3125 31.125V18C15.3132 17.42 15.5438 16.864 15.9539 16.4539C16.364 16.0438 16.92 15.8132 17.5 15.8125H30.625C31.205 15.8132 31.761 16.0438 32.1711 16.4539C32.5812 16.864 32.8118 17.42 32.8125 18V31.125C32.8118 31.705 32.5812 32.261 32.1711 32.6711C31.761 33.0812 31.205 33.3118 30.625 33.3125ZM17.5 18V31.125H30.6263L30.625 18H17.5Z"
          fill="black"
        />
        <path
          d="M16.4062 2.6875L14.8641 4.22969L17.6859 7.0625L12.0312 7.0625C10.0014 7.06476 8.05529 7.87212 6.61996 9.30746C5.18462 10.7428 4.37726 12.6889 4.375 14.7188V20.1875H6.5625V14.7188C6.56415 13.2689 7.14085 11.8788 8.16608 10.8536C9.19131 9.82835 10.5814 9.25165 12.0312 9.25H17.6859L14.8641 12.0828L16.4062 13.625L21.875 8.15625L16.4062 2.6875Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_628_1863">
          <rect width="35" height="35" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
