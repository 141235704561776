import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchProjectDetails, generateVideo } from '../api/api';
import { Header, Player } from '../components';
import { IconDoneProject } from '../components/Icons';
import styles from './ViewProject.module.scss';
import styled from 'styled-components';

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 364px;
  margin: auto;
  border: 1px solid #1480de;
  border-radius: 12px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  color: ${({ btn }) => btn.color || '#000000'};
  background-color: ${({ btn }) => btn.backgroundColor || '#ffffff'};
  border-color: ${({ btn }) => btn.borderColor || '#1480de'};
  font-weight: ${({ btn }) => btn.fontWeight || 400};

  &:hover {
    color: ${({ btn }) => btn.hover?.color || btn.color};
    background-color: ${({ btn }) => btn.hover?.backgroundColor || btn.backgroundColor};
    border-color: ${({ btn }) => btn.hover?.borderColor || btn.borderColor};
  }

  &:active {
    color: ${({ btn }) => btn.active?.color || btn.color};
    background-color: ${({ btn }) => btn.active?.backgroundColor || btn.backgroundColor};
    border-color: ${({ btn }) => btn.active?.borderColor || btn.borderColor};
  }
`;

const ViewProject = () => {
  const navigate = useNavigate();
  const { encryptedProjectId } = useParams();

  const [projectData, setProjectData] = useState({
    project: null,
    persBlocks: [],
    targetButtons: [],
    projectLink: '',
    total_combinations: 0,
    total_size_gb: 0,
    styles: {},
    poster_url: null
  });

  const [scriptText, setScriptText] = useState('');
  const [videoBlobUrl, setVideoBlobUrl] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await fetchProjectDetails(encryptedProjectId);
        const { project, pers_video_blocks, target_buttons, total_video_size } = response;

        if (project) {
          const { link, encrypted_id, styles, poster_url } = project;
          const { total_combinations, total_size_gb } = total_video_size;

          setProjectData({
            project,
            persBlocks: pers_video_blocks,
            targetButtons: target_buttons,
            projectLink: link,
            total_combinations,
            total_size_gb,
            styles,
            poster_url: poster_url
          });

          const generatedScript = generateScript(encrypted_id, pers_video_blocks, link);
          setScriptText(generatedScript);
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных проекта:', error);
      }
    };

    fetchProjectData();
  }, [encryptedProjectId]);

  useEffect(() => {
    if (projectData.styles && projectData.styles.background) {
      if (projectData.styles.background.backgroundColor) {
        document.body.style.background = projectData.styles.background.backgroundColor;
      } else if (projectData.styles.background.backgroundImage) {
        document.body.style.background = projectData.styles.background.backgroundImage;
        document.body.style.backgroundAttachment = 'fixed';
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundRepeat = 'no-repeat';
      }
    }

    return () => {
      document.body.style.background = '';
      document.body.style.backgroundAttachment = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundRepeat = '';
    };
  }, [projectData?.styles, projectData?.styles?.background]);

  const generateScript = (encryptedProjectId, persBlocks, projectLink) => {
    // eslint-disable-next-line no-unused-vars
    const blocks = persBlocks
      .map(block => {
        return `const ${block.name.replace(/\s+/g, '_').toLowerCase()} = form.${block.name
          .replace(/\s+/g, '_')
          .toLowerCase()}.value;`;
      })
      .join('\n    ');

    // eslint-disable-next-line no-unused-vars
    const params = persBlocks
      .map(block => {
        return `if (${block.name
          .replace(/\s+/g, '_')
          .toLowerCase()}) url.searchParams.append('${block.name.toLowerCase()}', ${block.name
          .replace(/\s+/g, '_')
          .toLowerCase()});`;
      })
      .join('\n    ');

    return `
<script>
    document.getElementById('demo_call').addEventListener('submit', async function(event) {
        event.preventDefault();
        const form = event.target;
        const data = {};
    
        form.querySelectorAll('input').forEach(input => {
            if (input.name !== 'tel' && input.value) { 
                data[input.name] = input.value.trim();
            }
        });
    
        form.querySelectorAll('select').forEach(select => {
            if (select.value) {
                data[select.name] = select.value;
            }
        });
        
        const customBlocks = { ...data };
        data.custom_blocks = customBlocks;
        data.encrypted_id = ${encryptedProjectId};
        
        try {
            const response = await fetch('https://api.persicom.ru/projects/get_short_link', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const result = await response.json();
                const videoUrl = \`${projectLink}?\${encodeURIComponent(result.short_link)}\`;
                window.location.assign(videoUrl.toString());
            } else {
                console.error('Ошибка при получении короткой ссылки', response.statusText);
            }
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
        }
    });
</script>
  `;
  };

  const handleGenerateVideo = async e => {
    e.preventDefault();

    try {
      setShowPopup(true);
      const response = await generateVideo(encryptedProjectId, e.target, projectData.persBlocks);

      const blob = new Blob([response], { type: 'video/mp4' });
      const videoUrl = URL.createObjectURL(blob);
      setVideoBlobUrl(videoUrl);
      setShowPopup(false);
    } catch (error) {
      console.error('Ошибка при генерации видео:', error);
      setShowPopup(false);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const copyTextToClipboard = async text => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Текст успешно скопирован в буфер обмена!');
    } catch (err) {
      console.error('Ошибка:', err);
    }
  };

  return (
    <div className={styles.view}>
      <Header />
      <div className={styles.viewProject}>
        <div className={styles.viewProjectWrap}>
          <h1 className={styles.title}>
            Просмотр проекта “{projectData?.project?.name}”{' '}
            {projectData?.project?.is_ready ? <IconDoneProject /> : null}
          </h1>
          <div className={styles.data}>
            <h2 className={styles.dataSubtitle}>
              Фактический объем: {projectData?.total_size_gb || '0'} Гб
            </h2>
            <h2 className={styles.dataSubtitle}>
              Всего финальных видео: {projectData?.total_combinations || '0'} шт
            </h2>
          </div>
        </div>
        <section className={styles.management}>
          <button onClick={() => navigate('/projects')} className={styles.button}>
            Вернуться на главную
          </button>
          <button
            onClick={() =>
              navigate(`/edit-project/${encryptedProjectId}`, {
                state: { encryptedProjectId, projectName: projectData.project?.name }
              })
            }
            className={styles.button}
          >
            Редактировать проект
          </button>
        </section>
      </div>
      <section className={styles.project}>
        <section className={styles.pers}>
          <h3 className={styles.persTitle}>Генерация примера видео</h3>
          <form id="lead-form" onSubmit={handleGenerateVideo} className={styles.form}>
            <p className={styles.formTitle}>Введите данные персов</p>
            {projectData.persBlocks.length > 0 ? (
              projectData.persBlocks.map((block, index) => (
                <div className={styles.formData} key={index}>
                  <label className={styles.formDataLabel}>
                    {block.name || `Перс-блок ${index + 1}`}
                  </label>
                  <input
                    type="text"
                    name={`block_${block.id}`}
                    className={styles.formDataInput}
                    placeholder={`Введите данные для ${block.name || `Перс-блок ${index + 1}`}`}
                  />
                </div>
              ))
            ) : (
              <span className={styles.formAlert}>Нет доступных перс-блоков для отображения.</span>
            )}
            <button className={styles.formButton} type="submit">
              Генерация проекта
            </button>
          </form>
        </section>
        <section className={styles.video}>
          <h3 className={styles.videoTitle}>Пример сгенерированного видео и постера</h3>
          {videoBlobUrl ? (
            <Player videoUrl={videoBlobUrl} posterUrl={projectData?.poster_url} className={styles.videoPlayer} />
          ) : (
            <Player videoUrl={null} posterUrl={projectData?.poster_url} className={styles.videoPlayer} />
          )}
          <div className={styles.targetButtons}>
            <h3 className={styles.videoTitle}>Пример целевых кнопок</h3>
            {projectData.targetButtons.map(({ id, name }) => (
              <StyledButton key={id} btn={projectData?.styles?.button}>
                {name}
              </StyledButton>
            ))}
          </div>
        </section>
        <section className={styles.script}>
          <h3 className={styles.scriptTitle}>Скрипт для интеграции</h3>
          <div className={styles.scriptContent}>
            <textarea
              className={styles.scriptContentTextarea}
              readOnly
              value={scriptText}
              rows="15"
              cols="80"
            />
            <button
              onClick={() => copyTextToClipboard(scriptText)}
              className={styles.buttonBottom}
              type="button"
            >
              Скопировать
            </button>
          </div>
        </section>
      </section>
      {showPopup && (
        <div onClick={handleClosePopup} className={styles.popup}>
          <div className={styles.popupWrapper}>
            <p className={styles.popupWrapperText}>Видео генерируется, пожалуйста, подождите...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewProject;
