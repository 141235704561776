import React, { useState } from 'react';
import styles from './TargetForm.module.scss';

const BTN_VARIANT = [
  { name: 'Ссылка', value: 'https://' },
  { name: 'E-mail', value: 'mailto:' },
  { name: 'Телефон', value: 'tel:' },
  { name: 'WhatsApp', value: 'https://wa.me/' },
  { name: 'Telegram', value: 'https://t.me/' }
];

export const TargetForm = ({ onClose, targetButtons, setTargetButtons }) => {
  const [inputValue, setInputValue] = useState({ name: '', link: '' });
  const [variant, setVariant] = useState(null);

  const { name, link } = inputValue;

  const handleVariantClick = selectedVariant => {
    setVariant(selectedVariant);
    setInputValue({ ...inputValue, link: selectedVariant.value });
  };

  const handleLinkChange = e => {
    const newLink = e.target.value;
    setInputValue({ ...inputValue, link: newLink });
  };

  return (
    <div className={styles.block}>
      <h2 className={styles.blockTitle}>Добавление кнопки</h2>
      <div className={styles.blockValue}>
        <label className={styles.blockValueLabel}>Текст</label>
        <input
          type="text"
          name="name_btn_target"
          placeholder="Введите название кнопки"
          value={name || ''}
          onChange={e => setInputValue({ ...inputValue, name: e.target.value })}
          className={styles.blockValueInput}
        />
      </div>
      <div className={styles.blockValue}>
        <ul className={styles.blockValueList}>
          {BTN_VARIANT.map(({ name, value }) => (
            <li
              key={name}
              onClick={() => handleVariantClick({ name, value })}
              className={
                name === variant?.name
                  ? `${styles.blockValueListItem} ${styles.active}`
                  : styles.blockValueListItem
              }
            >
              {name}
            </li>
          ))}
        </ul>
        <input
          type="text"
          name="link_target"
          placeholder="Вставьте ссылку на цел. действие"
          value={link || ''}
          onChange={handleLinkChange}
          className={styles.blockValueInput}
        />
      </div>
      <div className={styles.blockButtons}>
        <button
          onClick={() => {
            if (name && link) {
              setTargetButtons([...targetButtons, { name, link }]);
            }
            onClose();
          }}
          className={`${styles.blockButtonsBtn} ${styles.blue}`}
        >
          Добавить
        </button>
        <button onClick={onClose} className={styles.blockButtonsBtn}>
          Отмена
        </button>
      </div>
    </div>
  );
};
