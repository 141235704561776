import React, { useState } from 'react';
import { PersVideoEditor, VideoList, Personal, Modal } from '../';
import styles from './ProjectContent.module.scss';

export const ProjectContent = ({
  videos,
  setVideos,
  targetButtons,
  videoDetails,
  setVideoDetails,
  handleFileChange,
  handleDeleteVideo,
  handleViewVideo,
  setSelectedPersVideo,
  selectedPersVideo,
  setTargetButtons,
  persVideoInputRef,
  baseVideoInputRef,
  handleTargetButtonChange,
  handleDeletePersBlock,
  handleReplaceVideo,
  handleDefaultVideo,
  handleUpdatePersName,
  handleDeleteTargetButton,
  setLogoFile,
  setPosterFile
}) => {
  const [modalState, setModalState] = useState(false);

  return (
    <section className={styles.project}>
      <section className={styles.projectList}>
        <VideoList
          videos={videos}
          setVideos={setVideos}
          setModalState={setModalState}
          handleDeleteVideo={handleDeleteVideo}
          handleFileChange={handleFileChange}
          handleViewVideo={handleViewVideo}
          handleSelectPersVideo={setSelectedPersVideo}
          handleUpdatePersName={handleUpdatePersName}
          handleDeletePersBlock={handleDeletePersBlock}
          handleReplaceVideo={handleReplaceVideo}
          baseVideoInputRef={baseVideoInputRef}
        />
      </section>
      <section className={styles.projectPersonal}>
        <Personal
          videoDetails={videoDetails}
          setVideoDetails={setVideoDetails}
          targetButtons={targetButtons}
          setTargetButtons={setTargetButtons}
          setLogoFile={setLogoFile}
          setPosterFile={setPosterFile}
          handleTargetButtonChange={handleTargetButtonChange}
          handleDeleteTargetButton={handleDeleteTargetButton}
        />
      </section>
      <Modal isOpen={modalState} onClose={() => setModalState(false)}>
        <PersVideoEditor
          videos={videos}
          onClose={() => setModalState(false)}
          selectedPersVideo={selectedPersVideo}
          handleFileChange={handleFileChange}
          handleDefaultVideo={handleDefaultVideo}
          handleDeleteVideo={handleDeleteVideo}
          handleViewVideo={handleViewVideo}
          persVideoInputRef={persVideoInputRef}
        />
      </Modal>
    </section>
  );
};
