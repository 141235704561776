import React, { useState } from 'react';
import { useAuth } from '../utils/authProvider';
import logo from '../assets/images/logo.png';
import styles from './Login.module.scss';

const Login = () => {
  const [user, setUser] = useState({ email: '', password: '', error: false });

  const { login } = useAuth();

  const handleLogin = e => {
    e.preventDefault();
    login({ email: user.email, password: user.password });

    if (user.email !== 'kljhgfsd@mail.ru' && user.password !== 'hdf34278dsgcx') {
      setUser({ ...user, error: true });
    }
  };

  return (
    <section className={styles.login}>
      <section className={styles.header}>
        <img src={logo} alt="Логотип ПЕРСИКОММ" className={styles.headerLogo} />
        <h1 className={styles.headerTitle}>
          Создаем продукты для увеличения конверсии и среднего чека продаж
        </h1>
      </section>
      <section className={styles.login}>
        <form onSubmit={handleLogin} className={styles.form}>
          <label className={styles.formLabel}>
            Логин
            <input
              type="text"
              name="log"
              placeholder="Введите логин"
              value={user.email}
              onChange={e => setUser({ ...user, email: e.target.value })}
              className={styles.formInput}
            />
          </label>
          <label className={styles.formLabel}>
            Пароль
            <input
              type="password"
              name="pass"
              placeholder="Введите пароль"
              value={user.password}
              onChange={e => setUser({ ...user, password: e.target.value })}
              className={styles.formInput}
            />
          </label>
          <button type="submit" name="but_log" className={styles.formSubmit}>
            Войти
          </button>
          {user.error && <span className={styles.formError}>Неверные данные для входа</span>}
        </form>
      </section>
    </section>
  );
};

export default Login;
