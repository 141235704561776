import React, { useEffect, useState } from 'react';
import styles from './LeadTable.module.scss';
import { IconDelete, IconPlusCircle } from '../Icons';

export const LeadTable = ({ table, missingPersCells, selectedColumns }) => {
  const [rows, setRows] = useState([]);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState([]);

  useEffect(() => {
    if (table?.length) {
      setRows(table);
      setVisibleColumns(Object.keys(table[0] || {}).filter(key => key !== 'editable'));
    }
  }, [table]);

  const showAllColumns = () => {
    setVisibleColumns(Object.keys(rows[0] || {}).filter(key => key !== 'editable'));
  };

  const hideEmptyColumns = () => {
    const newVisibleColumns = Object.keys(rows[0] || {}).filter(header =>
      rows.some(row => row[header] !== '')
    );
    setVisibleColumns(newVisibleColumns);
  };

  const hideUnselectedColumns = () => {
    const newVisibleColumns = Object.keys(rows[0] || {}).filter(header =>
      Object.values(selectedColumns).includes(header)
    );
    setVisibleColumns(newVisibleColumns);
  };

  const handleAddRow = () => {
    const emptyRow =
      rows.length > 0
        ? Object.keys(rows[0]).reduce((acc, key) => {
            if (key !== 'editable') {
              acc[key] = '';
            }
            return acc;
          }, {})
        : { 'Номер телефона': '', 'E-mail': '', Имя: '', Товар: '', Месяц: '' };
    setRows([...rows, { ...emptyRow, editable: true }]);
    setIsAddingNew(true);
  };

  const handleSaveNewRow = () => {
    const newRows = [...rows];
    newRows[newRows.length - 1].editable = false;
    setRows(newRows);
    setIsAddingNew(false);
  };

  const handleDeleteRow = index => {
    setRows(rows.filter((_, i) => i !== index));
    if (isAddingNew && index === rows.length - 1) {
      setIsAddingNew(false);
    }
  };

  const handleChange = (index, key, value) => {
    const newRows = [...rows];
    newRows[index][key] = value;
    setRows(newRows);
  };

  const getInputType = header => {
    if (header === 'Номер телефона') {
      return 'tel';
    }
    return 'text';
  };

  return (
    <div className={styles.leads}>
      <h2 className={styles.leadsTitle}>Таблица лидов</h2>
      <div className={styles.leadsButtons}>
        <button className={styles.leadsButtonsBtn} onClick={showAllColumns}>
          Все столбцы
        </button>
        <button className={styles.leadsButtonsBtn} onClick={hideEmptyColumns}>
          Убрать пустые
        </button>
        <button className={styles.leadsButtonsBtn} onClick={hideUnselectedColumns}>
          Убрать невыбранные
        </button>
      </div>

      <div className={styles.wrapper}>
        {rows.length > 0 ? (
          <table className={styles.table}>
            <thead className={styles.tableThead}>
              <tr className={styles.tableTheadTr}>
                <th></th>
                {visibleColumns.map(header => (
                  <th key={header}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => {
                const isRowMissingPers = missingPersCells.some(cell => cell.rowIndex === rowIndex);

                return (
                  <tr
                    key={rowIndex}
                    className={`${styles.tableRows} ${isRowMissingPers ? styles.missingRow : ''}`}
                  >
                    <td className={styles.deleteBtn}>
                      <button onClick={() => handleDeleteRow(rowIndex)}>
                        <IconDelete />
                      </button>
                    </td>
                    {visibleColumns.map(header => (
                      <td
                        key={header}
                        className={`${styles.tableRowsTd} ${
                          missingPersCells.some(
                            cell => cell.rowIndex === rowIndex && cell.column === header
                          )
                            ? styles.missingPers
                            : ''
                        }`}
                      >
                        {row.editable ? (
                          <input
                            type={getInputType(header)}
                            value={row[header]}
                            onChange={e => handleChange(rowIndex, header, e.target.value)}
                          />
                        ) : (
                          row[header]
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className={styles.leadsTitle} style={{ marginBottom: 16, textAlign: 'center' }}>
            Нет данных. Импортируйте CSV файл.
          </p>
        )}
        {isAddingNew ? (
          <button onClick={handleSaveNewRow} className={styles.leadsButtonsBtn}>
            Сохранить новую строку
          </button>
        ) : (
          <button onClick={handleAddRow} className={`${styles.leadsButtonsBtn} ${styles.addBtn}`}>
            <IconPlusCircle /> Добавить новую строку
          </button>
        )}
      </div>
    </div>
  );
};
