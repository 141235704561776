import React, { useState } from 'react';
import styles from './TargetButtonManager.module.scss';
import { IconDelete } from '../Icons';

const TargetButton = ({ button, index, handleTargetButtonChange, handleDeleteTargetButton }) => {
  const [inputValue, setInputValue] = useState({ name: button.name, link: button.link });
  const { name, link } = inputValue;

  return (
    <div className={styles.block}>
      <button onClick={() => handleDeleteTargetButton(index)} className={styles.blockButton}>
        <IconDelete />
      </button>
      <div className={styles.blockValue}>
        <label className={styles.blockValueLabel}>Текст</label>
        <input
          type="text"
          name="name_btn_target"
          placeholder="Введите название кнопки"
          value={name || ''}
          onChange={e => setInputValue({ ...inputValue, name: e.target.value })}
          onBlur={() => handleTargetButtonChange(index, 'name', name)}
          className={styles.blockValueInput}
          style={{ marginBottom: 16 }}
        />
      </div>
      <div className={styles.blockValue}>
        <label className={styles.blockValueLabel}>Ссылка</label>
        <input
          type="text"
          name="link_target"
          placeholder="Вставьте ссылку на цел. действие"
          value={link || ''}
          onChange={e => setInputValue({ ...inputValue, link: e.target.value })}
          onBlur={() => handleTargetButtonChange(index, 'link', link)}
          className={styles.blockValueInput}
        />
      </div>
    </div>
  );
};

export const TargetButtonManager = ({
  targetButtons,
  handleTargetButtonChange,
  handleDeleteTargetButton
}) => {
  return (
    <>
      {targetButtons.map((button, index) => (
        <TargetButton
          key={index}
          button={button}
          index={index}
          handleTargetButtonChange={handleTargetButtonChange}
          handleDeleteTargetButton={handleDeleteTargetButton}
        />
      ))}
    </>
  );
};
