import React, { useCallback } from 'react';
import { VideoItem } from '../VideoItem';
import styles from './VideoList.module.scss';

export const VideoList = ({
  videos,
  setVideos,
  setModalState,
  handleUpdatePersName,
  handleDeleteVideo,
  handleReplaceVideo,
  handleViewVideo,
  handleSelectPersVideo,
  handleDeletePersBlock,
  baseVideoInputRef,
  handleFileChange
}) => {
  const getOptionsForBlock = useCallback(
    index => {
      const currentBlock = videos[index];

      return videos.filter(video => {
        return (
          video.type === 'pers_block' &&
          video.id !== currentBlock?.id &&
          (!video.is_twin || video.twin_block_id === currentBlock.twin_block_id) &&
          (!currentBlock.is_twin || currentBlock.twin_block_id === video.id)
        );
      });
    },
    [videos]
  );

  const isDropdownDisabled = useCallback(
    index => {
      return videos.some(
        video => video.type === 'pers_block' && video.twin_block_id === videos[index]?.id
      );
    },
    [videos]
  );

  const handleSelectTwinBlock = useCallback(
    (index, block) => {
      const updatedVideos = videos.map((video, idx) =>
        idx === index ? { ...video, is_twin: true, twin_block_id: block.id } : video
      );
      setVideos(updatedVideos);
    },
    [videos, setVideos]
  );

  const handleClearTwinBlockSelection = useCallback(
    index => {
      const updatedVideos = videos.map((video, idx) =>
        idx === index ? { ...video, is_twin: false, twin_block_id: null } : video
      );
      setVideos(updatedVideos);
    },
    [videos, setVideos]
  );

  return (
    <div className={styles.videos}>
      {videos.map((video, index) => (
        <VideoItem
          key={index}
          video={video}
          videos={videos}
          index={index}
          setModalState={setModalState}
          baseVideoInputRef={baseVideoInputRef}
          handleFileChange={handleFileChange}
          handleUpdatePersName={handleUpdatePersName}
          handleDeleteVideo={handleDeleteVideo}
          handleReplaceVideo={handleReplaceVideo}
          handleViewVideo={handleViewVideo}
          handleSelectPersVideo={handleSelectPersVideo}
          handleDeletePersBlock={handleDeletePersBlock}
          getOptionsForBlock={() => getOptionsForBlock(index)}
          isDropdownDisabled={() => isDropdownDisabled(index)}
          handleSelectTwinBlock={handleSelectTwinBlock}
          handleClearTwinBlockSelection={handleClearTwinBlockSelection}
        />
      ))}
    </div>
  );
};
