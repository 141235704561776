import React, { useEffect, useRef, useState } from 'react';
import styles from './Export.module.scss';
import { Header, ShareSettings } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchProjectDetails, generateLinks, getAllPers, importCsv, leadExport } from '../api/api';
import { LeadTable } from '../components/LeadTable/LeadTable';

const Export = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const csvRef = useRef();
  const { encryptedProjectId } = location.state || {};

  const [project, setProject] = useState({
    projectId: '',
    generations: null,
    table: []
  });
  const [persData, setPersData] = useState([]);
  const [projectData, setProjectData] = useState(null);
  const [missingPersCells, setMissingPersCells] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [selectedData, setSelectedData] = useState({
    encrypted_id: '',
    custom_blocks: {}
  });
  const [fullRows, setFullRows] = useState({});
  const [links, setLinks] = useState(null);

  useEffect(() => {
    const getProject = async () => {
      try {
        const result = await fetchProjectDetails(encryptedProjectId);
        setProject({
          projectId: result.project.encrypted_id || '',
          generations: result.total_video_size.total_combinations || 0
        });
        setProjectData(result.project);

        const pers = await getAllPers(encryptedProjectId);
        setPersData(pers);
      } catch (error) {
        console.log(error);
      }
    };

    getProject();
  }, [encryptedProjectId]);

  const handleImport = async e => {
    const file = e.target.files;
    if (!file.length) return;

    const res = await importCsv(file);

    setProject(prev => ({ ...prev, table: res }));
  };

  const triggerFileInput = () => {
    csvRef.current.click();
  };

  const hanleGenerateClick = async () => {
    const res = await generateLinks(selectedData);
    setLinks(res);
  };

  const handleCheckPers = pers => {
    setSelectedData({ ...selectedData, encrypted_id: project.projectId, custom_blocks: pers });
  };

  const handleExport = async () => {
    const linksData = links?.final_links || {};

    const updatedFullRows = Object.values(fullRows).map(row => {
      const phoneNumber = row['Телефон'];
      if (linksData[phoneNumber]) {
        return {
          ...row,
          Ссылка: linksData[phoneNumber]
        };
      }
      return row;
    });

    const exportData = updatedFullRows;

    try {
      const data = await leadExport(exportData);

      const blob = new Blob([data], { type: 'text/csv' });

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export.csv');
      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Ошибка при экспорте данных в CSV:', error);
    }
  };

  return (
    <section className={styles.export}>
      <Header />
      <section className={styles.exportHead}>
        <div className={styles.exportHeadText}>
          <h1 className={styles.exportHeadTextTitle}>
            Экспорт проекта "{projectData?.name || ''}"
          </h1>
          <h2 className={styles.exportHeadTextSubtitle}>
            Генераций: {project.generations || '0'} шт
          </h2>
        </div>
        <div className={styles.management}>
          <button onClick={() => navigate('/projects')} className={styles.managementButton}>
            На главную
          </button>
          <input ref={csvRef} type="file" onChange={handleImport} style={{ display: 'none' }} />
          <button className={styles.managementButton} onClick={triggerFileInput}>
            Импорт CSV
          </button>
          <button
            className={
              links
                ? `${styles.managementButton} ${styles.blue}`
                : `${styles.managementButton} ${styles.disabled}`
            }
            onClick={handleExport}
          >
            Экспортировать
          </button>
        </div>
      </section>

      <section className={styles.exportContent}>
        <ShareSettings
          pers={persData}
          links={links}
          table={project.table}
          setFullRows={setFullRows}
          setMissingPersCells={setMissingPersCells}
          setSelectedColumns={setSelectedColumns}
          handleCheckPers={handleCheckPers}
          hanleGenerateClick={hanleGenerateClick}
        />
        <LeadTable
          table={project.table}
          missingPersCells={missingPersCells}
          selectedColumns={selectedColumns}
        />
      </section>
    </section>
  );
};

export default Export;
