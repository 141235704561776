import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { updateBaseVideoTitle } from '../../api/api';
import styles from './VideoItem.module.scss';
import { IconDelete, IconReplace, IconSettings, IconUpload, IconView } from '../Icons';

export const VideoItem = ({
  video,
  videos,
  index,
  setModalState,
  baseVideoInputRef,
  handleFileChange,
  handleUpdatePersName,
  handleDeleteVideo,
  handleReplaceVideo,
  handleViewVideo,
  handleSelectPersVideo,
  handleDeletePersBlock,
  getOptionsForBlock,
  isDropdownDisabled,
  handleSelectTwinBlock,
  handleClearTwinBlockSelection
}) => {
  const [inputBaseValue, setInputBaseValue] = useState(
    video.title ? video.title : `Основное видео ${index + 1}`
  );
  const [inputPersValue, setInputPersValue] = useState(
    video.name.charAt(0).toUpperCase() + video.name.slice(1)
  );
  const [selectedTwinBlock, setSelectedTwinBlock] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const handleSelect = block => {
    setIsDropdownOpen(false);
    setSelectedTwinBlock(block);
    handleSelectTwinBlock(index, block);
  };

  const handleClearSelection = () => {
    setSelectedTwinBlock(null);
    handleClearTwinBlockSelection(index);
  };

  const handleUpdateBaseName = async (name, id) => {
    await updateBaseVideoTitle(id, name);
  };

  useEffect(() => {
    if (video?.twin_block_id) {
      const twinBlock = videos.find(v => v.id === video.twin_block_id);
      if (twinBlock) {
        setSelectedTwinBlock(twinBlock);
      } else {
        setSelectedTwinBlock(null);
      }
    } else {
      setSelectedTwinBlock(null);
    }
  }, [video, videos]);

  const handleFileInputChange = e => {
    if (video.url) {
      handleReplaceVideo(index, e);
    } else {
      handleFileChange(e, 'base', index);
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`${video.type} ${styles.item}`}>
      <div className={styles.itemAbout}>
        {video.type === 'pers_block' ? (
          <>
            <small className={styles.itemAboutSmall}>Перс</small>
            <input
              type="text"
              value={inputPersValue}
              onChange={e => setInputPersValue(e.target.value)}
              onBlur={() => handleUpdatePersName(index, inputPersValue, video.id)}
              className={styles.itemAboutInput}
            />
          </>
        ) : (
          <>
            <small className={styles.itemAboutSmall}>Основное</small>
            <input
              type="text"
              value={inputBaseValue}
              onChange={e => setInputBaseValue(e.target.value)}
              onBlur={() => handleUpdateBaseName(inputBaseValue, video.id)}
              className={styles.itemAboutInput}
            />
            <p className={styles.itemAboutAnnotation}>Продолжительность: </p>
            <p className={styles.itemAboutAnnotation}>{video.name}</p>
          </>
        )}
        {video.type === 'pers_block' && (
          <>
            <p className={styles.itemAboutAnnotation}>
              Количество видео: {video.videos.length} штук
            </p>
            <div
              className={
                isDropdownDisabled() ? `${styles.dropdown} ${styles.disabled}` : styles.dropdown
              }
              ref={dropdownRef}
              onClick={toggleDropdown}
              disabled={isDropdownDisabled()}
            >
              <div className={styles.dropdownTwin}>
                {selectedTwinBlock ? (
                  <span className={styles.dropdownTwinValue}>
                    Побратим с <span style={{ fontWeight: 'bold' }}>{selectedTwinBlock.name}</span>
                    <button
                      onClick={handleClearSelection}
                      className={styles.dropdownTwinValueButton}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <button className={styles.dropdownTwinValueButton}>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </button>
                  </span>
                ) : (
                  <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                    Выбрать побратим
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                )}
              </div>
              {isDropdownOpen && (
                <div className={styles.dropdownMenu}>
                  {getOptionsForBlock().map(block => (
                    <button
                      key={block.id}
                      onClick={() => handleSelect(block)}
                      className={styles.dropdownMenuItem}
                    >
                      {block.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className={`${video.type} ${styles.buttons}`}>
        {video.type === 'base' && (
          <>
            <input
              ref={baseVideoInputRef}
              type="file"
              id={video.url ? `replace-base-video-${index}` : 'upload-base-video'}
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
            />
            {video.url && (
              <button onClick={() => handleViewVideo(video.url)} className={styles.buttonsButton}>
                <IconView />
              </button>
            )}
            {video.type === 'base' && video.url ? (
              <button
                onClick={() => document.getElementById(`replace-base-video-${index}`).click()}
                className={styles.buttonsButton}
              >
                <IconReplace />
              </button>
            ) : (
              <button
                onClick={() => document.getElementById(`upload-base-video`).click()}
                className={styles.buttonsButton}
              >
                <IconUpload />
              </button>
            )}

            <button
              onClick={() => handleDeleteVideo(index, video.type)}
              className={styles.buttonsButton}
            >
              <IconDelete />
            </button>
          </>
        )}
        {video.type === 'pers_block' && (
          <>
            <button
              onClick={() => {
                handleSelectPersVideo(index);
                setModalState(true);
              }}
              className={styles.buttonsButton}
            >
              <IconSettings />
            </button>
            <button onClick={() => handleDeletePersBlock(index)} className={styles.buttonsButton}>
              <IconDelete />
            </button>
          </>
        )}
      </div>
    </div>
  );
};
